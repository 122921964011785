export const states = {
  "New York": [
    "New York",
    "Buffalo",
    "Rochester",
    "Yonkers",
    "Syracuse",
    "Albany",
    "New Rochelle",
    "Mount Vernon",
    "Schenectady",
    "Utica",
    "White Plains",
    "Hempstead",
    "Troy",
    "Niagara Falls",
    "Binghamton",
    "Freeport",
    "Valley Stream",
  ],
  California: [
    "Los Angeles",
    "San Diego",
    "San Jose",
    "San Francisco",
    "Fresno",
    "Sacramento",
    "Long Beach",
    "Oakland",
    "Bakersfield",
    "Anaheim",
    "Santa Ana",
    "Riverside",
    "Stockton",
    "Chula Vista",
    "Irvine",
    "Fremont",
    "San Bernardino",
    "Modesto",
    "Fontana",
    "Oxnard",
    "Moreno Valley",
    "Huntington Beach",
    "Glendale",
    "Santa Clarita",
    "Garden Grove",
    "Oceanside",
    "Rancho Cucamonga",
    "Santa Rosa",
    "Ontario",
    "Lancaster",
    "Elk Grove",
    "Corona",
    "Palmdale",
    "Salinas",
    "Pomona",
    "Hayward",
    "Escondido",
    "Torrance",
    "Sunnyvale",
    "Orange",
    "Fullerton",
    "Pasadena",
    "Thousand Oaks",
    "Visalia",
    "Simi Valley",
    "Concord",
    "Roseville",
    "Victorville",
    "Santa Clara",
    "Vallejo",
    "Berkeley",
    "El Monte",
    "Downey",
    "Costa Mesa",
    "Inglewood",
    "Carlsbad",
    "San Buenaventura (Ventura)",
    "Fairfield",
    "West Covina",
    "Murrieta",
    "Richmond",
    "Norwalk",
    "Antioch",
    "Temecula",
    "Burbank",
    "Daly City",
    "Rialto",
    "Santa Maria",
    "El Cajon",
    "San Mateo",
    "Clovis",
    "Compton",
    "Jurupa Valley",
    "Vista",
    "South Gate",
    "Mission Viejo",
    "Vacaville",
    "Carson",
    "Hesperia",
    "Santa Monica",
    "Westminster",
    "Redding",
    "Santa Barbara",
    "Chico",
    "Newport Beach",
    "San Leandro",
    "San Marcos",
    "Whittier",
    "Hawthorne",
    "Citrus Heights",
    "Tracy",
    "Alhambra",
    "Livermore",
    "Buena Park",
    "Menifee",
    "Hemet",
    "Lakewood",
    "Merced",
    "Chino",
    "Indio",
    "Redwood City",
    "Lake Forest",
    "Napa",
    "Tustin",
    "Bellflower",
    "Mountain View",
    "Chino Hills",
    "Baldwin Park",
    "Alameda",
    "Upland",
    "San Ramon",
    "Folsom",
    "Pleasanton",
    "Union City",
    "Perris",
    "Manteca",
    "Lynwood",
    "Apple Valley",
    "Redlands",
    "Turlock",
    "Milpitas",
    "Redondo Beach",
    "Rancho Cordova",
    "Yorba Linda",
    "Palo Alto",
    "Davis",
    "Camarillo",
    "Walnut Creek",
    "Pittsburg",
    "South San Francisco",
    "Yuba City",
    "San Clemente",
    "Laguna Niguel",
    "Pico Rivera",
    "Montebello",
    "Lodi",
    "Madera",
    "Santa Cruz",
    "La Habra",
    "Encinitas",
    "Monterey Park",
    "Tulare",
    "Cupertino",
    "Gardena",
    "National City",
    "Rocklin",
    "Petaluma",
    "Huntington Park",
    "San Rafael",
    "La Mesa",
    "Arcadia",
    "Fountain Valley",
    "Diamond Bar",
    "Woodland",
    "Santee",
    "Lake Elsinore",
    "Porterville",
    "Paramount",
    "Eastvale",
    "Rosemead",
    "Hanford",
    "Highland",
    "Brentwood",
    "Novato",
    "Colton",
    "Cathedral City",
    "Delano",
    "Yucaipa",
    "Watsonville",
    "Placentia",
    "Glendora",
    "Gilroy",
    "Palm Desert",
    "Cerritos",
    "West Sacramento",
    "Aliso Viejo",
    "Poway",
    "La Mirada",
    "Rancho Santa Margarita",
    "Cypress",
    "Dublin",
    "Covina",
    "Azusa",
    "Palm Springs",
    "San Luis Obispo",
    "Ceres",
    "San Jacinto",
    "Lincoln",
    "Newark",
    "Lompoc",
    "El Centro",
    "Danville",
    "Bell Gardens",
    "Coachella",
    "Rancho Palos Verdes",
    "San Bruno",
    "Rohnert Park",
    "Brea",
    "La Puente",
    "Campbell",
    "San Gabriel",
    "Beaumont",
    "Morgan Hill",
    "Culver City",
    "Calexico",
    "Stanton",
    "La Quinta",
    "Pacifica",
    "Montclair",
    "Oakley",
    "Monrovia",
    "Los Banos",
    "Martinez",
  ],
  Illinois: [
    "Chicago",
    "Aurora",
    "Rockford",
    "Joliet",
    "Naperville",
    "Springfield",
    "Peoria",
    "Elgin",
    "Waukegan",
    "Cicero",
    "Champaign",
    "Bloomington",
    "Arlington Heights",
    "Evanston",
    "Decatur",
    "Schaumburg",
    "Bolingbrook",
    "Palatine",
    "Skokie",
    "Des Plaines",
    "Orland Park",
    "Tinley Park",
    "Oak Lawn",
    "Berwyn",
    "Mount Prospect",
    "Normal",
    "Wheaton",
    "Hoffman Estates",
    "Oak Park",
    "Downers Grove",
    "Elmhurst",
    "Glenview",
    "DeKalb",
    "Lombard",
    "Belleville",
    "Moline",
    "Buffalo Grove",
    "Bartlett",
    "Urbana",
    "Quincy",
    "Crystal Lake",
    "Plainfield",
    "Streamwood",
    "Carol Stream",
    "Romeoville",
    "Rock Island",
    "Hanover Park",
    "Carpentersville",
    "Wheeling",
    "Park Ridge",
    "Addison",
    "Calumet City",
  ],
  Texas: [
    "Houston",
    "San Antonio",
    "Dallas",
    "Austin",
    "Fort Worth",
    "El Paso",
    "Arlington",
    "Corpus Christi",
    "Plano",
    "Laredo",
    "Lubbock",
    "Garland",
    "Irving",
    "Amarillo",
    "Grand Prairie",
    "Brownsville",
    "Pasadena",
    "McKinney",
    "Mesquite",
    "McAllen",
    "Killeen",
    "Frisco",
    "Waco",
    "Carrollton",
    "Denton",
    "Midland",
    "Abilene",
    "Beaumont",
    "Round Rock",
    "Odessa",
    "Wichita Falls",
    "Richardson",
    "Lewisville",
    "Tyler",
    "College Station",
    "Pearland",
    "San Angelo",
    "Allen",
    "League City",
    "Sugar Land",
    "Longview",
    "Edinburg",
    "Mission",
    "Bryan",
    "Baytown",
    "Pharr",
    "Temple",
    "Missouri City",
    "Flower Mound",
    "Harlingen",
    "North Richland Hills",
    "Victoria",
    "Conroe",
    "New Braunfels",
    "Mansfield",
    "Cedar Park",
    "Rowlett",
    "Port Arthur",
    "Euless",
    "Georgetown",
    "Pflugerville",
    "DeSoto",
    "San Marcos",
    "Grapevine",
    "Bedford",
    "Galveston",
    "Cedar Hill",
    "Texas City",
    "Wylie",
    "Haltom City",
    "Keller",
    "Coppell",
    "Rockwall",
    "Huntsville",
    "Duncanville",
    "Sherman",
    "The Colony",
    "Burleson",
    "Hurst",
    "Lancaster",
    "Texarkana",
    "Friendswood",
    "Weslaco",
  ],
  Pennsylvania: [
    "Philadelphia",
    "Pittsburgh",
    "Allentown",
    "Erie",
    "Reading",
    "Scranton",
    "Bethlehem",
    "Lancaster",
    "Harrisburg",
    "Altoona",
    "York",
    "State College",
    "Wilkes-Barre",
  ],
  Arizona: [
    "Phoenix",
    "Tucson",
    "Mesa",
    "Chandler",
    "Glendale",
    "Scottsdale",
    "Gilbert",
    "Tempe",
    "Peoria",
    "Surprise",
    "Yuma",
    "Avondale",
    "Goodyear",
    "Flagstaff",
    "Buckeye",
    "Lake Havasu City",
    "Casa Grande",
    "Sierra Vista",
    "Maricopa",
    "Oro Valley",
    "Prescott",
    "Bullhead City",
    "Prescott Valley",
    "Marana",
    "Apache Junction",
  ],
  Florida: [
    "Jacksonville",
    "Miami",
    "Tampa",
    "Orlando",
    "St. Petersburg",
    "Hialeah",
    "Tallahassee",
    "Fort Lauderdale",
    "Port St. Lucie",
    "Cape Coral",
    "Pembroke Pines",
    "Hollywood",
    "Miramar",
    "Gainesville",
    "Coral Springs",
    "Miami Gardens",
    "Clearwater",
    "Palm Bay",
    "Pompano Beach",
    "West Palm Beach",
    "Lakeland",
    "Davie",
    "Miami Beach",
    "Sunrise",
    "Plantation",
    "Boca Raton",
    "Deltona",
    "Largo",
    "Deerfield Beach",
    "Palm Coast",
    "Melbourne",
    "Boynton Beach",
    "Lauderhill",
    "Weston",
    "Fort Myers",
    "Kissimmee",
    "Homestead",
    "Tamarac",
    "Delray Beach",
    "Daytona Beach",
    "North Miami",
    "Wellington",
    "North Port",
    "Jupiter",
    "Ocala",
    "Port Orange",
    "Margate",
    "Coconut Creek",
    "Sanford",
    "Sarasota",
    "Pensacola",
    "Bradenton",
    "Palm Beach Gardens",
    "Pinellas Park",
    "Coral Gables",
    "Doral",
    "Bonita Springs",
    "Apopka",
    "Titusville",
    "North Miami Beach",
    "Oakland Park",
    "Fort Pierce",
    "North Lauderdale",
    "Cutler Bay",
    "Altamonte Springs",
    "St. Cloud",
    "Greenacres",
    "Ormond Beach",
    "Ocoee",
    "Hallandale Beach",
    "Winter Garden",
    "Aventura",
  ],
  Indiana: [
    "Indianapolis",
    "Fort Wayne",
    "Evansville",
    "South Bend",
    "Carmel",
    "Bloomington",
    "Fishers",
    "Hammond",
    "Gary",
    "Muncie",
    "Lafayette",
    "Terre Haute",
    "Kokomo",
    "Anderson",
    "Noblesville",
    "Greenwood",
    "Elkhart",
    "Mishawaka",
    "Lawrence",
    "Jeffersonville",
    "Columbus",
    "Portage",
  ],
  Ohio: [
    "Columbus",
    "Cleveland",
    "Cincinnati",
    "Toledo",
    "Akron",
    "Dayton",
    "Parma",
    "Canton",
    "Youngstown",
    "Lorain",
    "Hamilton",
    "Springfield",
    "Kettering",
    "Elyria",
    "Lakewood",
    "Cuyahoga Falls",
    "Middletown",
    "Euclid",
    "Newark",
    "Mansfield",
    "Mentor",
    "Beavercreek",
    "Cleveland Heights",
    "Strongsville",
    "Dublin",
    "Fairfield",
    "Findlay",
    "Warren",
    "Lancaster",
    "Lima",
    "Huber Heights",
    "Westerville",
    "Marion",
    "Grove City",
  ],
  "North Carolina": [
    "Charlotte",
    "Raleigh",
    "Greensboro",
    "Durham",
    "Winston-Salem",
    "Fayetteville",
    "Cary",
    "Wilmington",
    "High Point",
    "Greenville",
    "Asheville",
    "Concord",
    "Gastonia",
    "Jacksonville",
    "Chapel Hill",
    "Rocky Mount",
    "Burlington",
    "Wilson",
    "Huntersville",
    "Kannapolis",
    "Apex",
    "Hickory",
    "Goldsboro",
  ],
  Michigan: [
    "Detroit",
    "Grand Rapids",
    "Warren",
    "Sterling Heights",
    "Ann Arbor",
    "Lansing",
    "Flint",
    "Dearborn",
    "Livonia",
    "Westland",
    "Troy",
    "Farmington Hills",
    "Kalamazoo",
    "Wyoming",
    "Southfield",
    "Rochester Hills",
    "Taylor",
    "Pontiac",
    "St. Clair Shores",
    "Royal Oak",
    "Novi",
    "Dearborn Heights",
    "Battle Creek",
    "Saginaw",
    "Kentwood",
    "East Lansing",
    "Roseville",
    "Portage",
    "Midland",
    "Lincoln Park",
    "Muskegon",
  ],
  Tennessee: [
    "Memphis",
    "Nashville-Davidson",
    "Knoxville",
    "Chattanooga",
    "Clarksville",
    "Murfreesboro",
    "Jackson",
    "Franklin",
    "Johnson City",
    "Bartlett",
    "Hendersonville",
    "Kingsport",
    "Collierville",
    "Cleveland",
    "Smyrna",
    "Germantown",
    "Brentwood",
  ],
  Massachusetts: [
    "Boston",
    "Worcester",
    "Springfield",
    "Lowell",
    "Cambridge",
    "New Bedford",
    "Brockton",
    "Quincy",
    "Lynn",
    "Fall River",
    "Newton",
    "Lawrence",
    "Somerville",
    "Waltham",
    "Haverhill",
    "Malden",
    "Medford",
    "Taunton",
    "Chicopee",
    "Weymouth Town",
    "Revere",
    "Peabody",
    "Methuen",
    "Barnstable Town",
    "Pittsfield",
    "Attleboro",
    "Everett",
    "Salem",
    "Westfield",
    "Leominster",
    "Fitchburg",
    "Beverly",
    "Holyoke",
    "Marlborough",
    "Woburn",
    "Chelsea",
  ],
  Washington: [
    "Seattle",
    "Spokane",
    "Tacoma",
    "Vancouver",
    "Bellevue",
    "Kent",
    "Everett",
    "Renton",
    "Yakima",
    "Federal Way",
    "Spokane Valley",
    "Bellingham",
    "Kennewick",
    "Auburn",
    "Pasco",
    "Marysville",
    "Lakewood",
    "Redmond",
    "Shoreline",
    "Richland",
    "Kirkland",
    "Burien",
    "Sammamish",
    "Olympia",
    "Lacey",
    "Edmonds",
    "Bremerton",
    "Puyallup",
  ],
  Colorado: [
    "Denver",
    "Colorado Springs",
    "Aurora",
    "Fort Collins",
    "Lakewood",
    "Thornton",
    "Arvada",
    "Westminster",
    "Pueblo",
    "Centennial",
    "Boulder",
    "Greeley",
    "Longmont",
    "Loveland",
    "Grand Junction",
    "Broomfield",
    "Castle Rock",
    "Commerce City",
    "Parker",
    "Littleton",
    "Northglenn",
  ],
  "District of Columbia": ["Washington"],
  Maryland: [
    "Baltimore",
    "Frederick",
    "Rockville",
    "Gaithersburg",
    "Bowie",
    "Hagerstown",
    "Annapolis",
  ],
  Kentucky: [
    "Louisville/Jefferson County",
    "Lexington-Fayette",
    "Bowling Green",
    "Owensboro",
    "Covington",
  ],
  Oregon: [
    "Portland",
    "Eugene",
    "Salem",
    "Gresham",
    "Hillsboro",
    "Beaverton",
    "Bend",
    "Medford",
    "Springfield",
    "Corvallis",
    "Albany",
    "Tigard",
    "Lake Oswego",
    "Keizer",
  ],
  Oklahoma: [
    "Oklahoma City",
    "Tulsa",
    "Norman",
    "Broken Arrow",
    "Lawton",
    "Edmond",
    "Moore",
    "Midwest City",
    "Enid",
    "Stillwater",
    "Muskogee",
  ],
  Wisconsin: [
    "Milwaukee",
    "Madison",
    "Green Bay",
    "Kenosha",
    "Racine",
    "Appleton",
    "Waukesha",
    "Eau Claire",
    "Oshkosh",
    "Janesville",
    "West Allis",
    "La Crosse",
    "Sheboygan",
    "Wauwatosa",
    "Fond du Lac",
    "New Berlin",
    "Wausau",
    "Brookfield",
    "Greenfield",
    "Beloit",
  ],
  Nevada: [
    "Las Vegas",
    "Henderson",
    "Reno",
    "North Las Vegas",
    "Sparks",
    "Carson City",
  ],
  "New Mexico": [
    "Albuquerque",
    "Las Cruces",
    "Rio Rancho",
    "Santa Fe",
    "Roswell",
    "Farmington",
    "Clovis",
  ],
  Missouri: [
    "Kansas City",
    "St. Louis",
    "Springfield",
    "Independence",
    "Columbia",
    "Lee's Summit",
    "O'Fallon",
    "St. Joseph",
    "St. Charles",
    "St. Peters",
    "Blue Springs",
    "Florissant",
    "Joplin",
    "Chesterfield",
    "Jefferson City",
    "Cape Girardeau",
  ],
  Virginia: [
    "Virginia Beach",
    "Norfolk",
    "Chesapeake",
    "Richmond",
    "Newport News",
    "Alexandria",
    "Hampton",
    "Roanoke",
    "Portsmouth",
    "Suffolk",
    "Lynchburg",
    "Harrisonburg",
    "Leesburg",
    "Charlottesville",
    "Danville",
    "Blacksburg",
    "Manassas",
  ],
  Georgia: [
    "Atlanta",
    "Columbus",
    "Augusta-Richmond County",
    "Savannah",
    "Athens-Clarke County",
    "Sandy Springs",
    "Roswell",
    "Macon",
    "Johns Creek",
    "Albany",
    "Warner Robins",
    "Alpharetta",
    "Marietta",
    "Valdosta",
    "Smyrna",
    "Dunwoody",
  ],
  Nebraska: ["Omaha", "Lincoln", "Bellevue", "Grand Island"],
  Minnesota: [
    "Minneapolis",
    "St. Paul",
    "Rochester",
    "Duluth",
    "Bloomington",
    "Brooklyn Park",
    "Plymouth",
    "St. Cloud",
    "Eagan",
    "Woodbury",
    "Maple Grove",
    "Eden Prairie",
    "Coon Rapids",
    "Burnsville",
    "Blaine",
    "Lakeville",
    "Minnetonka",
    "Apple Valley",
    "Edina",
    "St. Louis Park",
    "Mankato",
    "Maplewood",
    "Moorhead",
    "Shakopee",
  ],
  Kansas: [
    "Wichita",
    "Overland Park",
    "Kansas City",
    "Olathe",
    "Topeka",
    "Lawrence",
    "Shawnee",
    "Manhattan",
    "Lenexa",
    "Salina",
    "Hutchinson",
  ],
  Louisiana: [
    "New Orleans",
    "Baton Rouge",
    "Shreveport",
    "Lafayette",
    "Lake Charles",
    "Kenner",
    "Bossier City",
    "Monroe",
    "Alexandria",
  ],
  Hawaii: ["Honolulu"],
  Alaska: ["Anchorage"],
  "New Jersey": [
    "Newark",
    "Jersey City",
    "Paterson",
    "Elizabeth",
    "Clifton",
    "Trenton",
    "Camden",
    "Passaic",
    "Union City",
    "Bayonne",
    "East Orange",
    "Vineland",
    "New Brunswick",
    "Hoboken",
    "Perth Amboy",
    "West New York",
    "Plainfield",
    "Hackensack",
    "Sayreville",
    "Kearny",
    "Linden",
    "Atlantic City",
  ],
  Idaho: [
    "Boise City",
    "Nampa",
    "Meridian",
    "Idaho Falls",
    "Pocatello",
    "Caldwell",
    "Coeur d'Alene",
    "Twin Falls",
  ],
  Alabama: [
    "Birmingham",
    "Montgomery",
    "Mobile",
    "Huntsville",
    "Tuscaloosa",
    "Hoover",
    "Dothan",
    "Auburn",
    "Decatur",
    "Madison",
    "Florence",
    "Gadsden",
  ],
  Iowa: [
    "Des Moines",
    "Cedar Rapids",
    "Davenport",
    "Sioux City",
    "Iowa City",
    "Waterloo",
    "Council Bluffs",
    "Ames",
    "West Des Moines",
    "Dubuque",
    "Ankeny",
    "Urbandale",
    "Cedar Falls",
  ],
  Arkansas: [
    "Little Rock",
    "Fort Smith",
    "Fayetteville",
    "Springdale",
    "Jonesboro",
    "North Little Rock",
    "Conway",
    "Rogers",
    "Pine Bluff",
    "Bentonville",
  ],
  Utah: [
    "Salt Lake City",
    "West Valley City",
    "Provo",
    "West Jordan",
    "Orem",
    "Sandy",
    "Ogden",
    "St. George",
    "Layton",
    "Taylorsville",
    "South Jordan",
    "Lehi",
    "Logan",
    "Murray",
    "Draper",
    "Bountiful",
    "Riverton",
    "Roy",
  ],
  "Rhode Island": [
    "Providence",
    "Warwick",
    "Cranston",
    "Pawtucket",
    "East Providence",
    "Woonsocket",
  ],
  Mississippi: [
    "Jackson",
    "Gulfport",
    "Southaven",
    "Hattiesburg",
    "Biloxi",
    "Meridian",
  ],
  "South Dakota": ["Sioux Falls", "Rapid City"],
  Connecticut: [
    "Bridgeport",
    "New Haven",
    "Stamford",
    "Hartford",
    "Waterbury",
    "Norwalk",
    "Danbury",
    "New Britain",
    "Meriden",
    "Bristol",
    "West Haven",
    "Milford",
    "Middletown",
    "Norwich",
    "Shelton",
  ],
  "South Carolina": [
    "Columbia",
    "Charleston",
    "North Charleston",
    "Mount Pleasant",
    "Rock Hill",
    "Greenville",
    "Summerville",
    "Sumter",
    "Goose Creek",
    "Hilton Head Island",
    "Florence",
    "Spartanburg",
  ],
  "New Hampshire": ["Manchester", "Nashua", "Concord"],
  "North Dakota": ["Fargo", "Bismarck", "Grand Forks", "Minot"],
  Montana: ["Billings", "Missoula", "Great Falls", "Bozeman"],
  Delaware: ["Wilmington", "Dover"],
  Maine: ["Portland"],
  Wyoming: ["Cheyenne", "Casper"],
  "West Virginia": ["Charleston", "Huntington"],
  Vermont: ["Burlington"],
};

// export const DOMAIN = "http://localhost:4000";
// export const DOMAIN = 'https://puppeteer-render-rqmp.onrender.com';
export const DOMAIN = "https://cdn1.leadgorilla.app";

export const DOMAIN_SERVER = "https://dev.leadgorilla.io";

export const scrapeData = [
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["info@rooffixsa.com"],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWNwNzlma2Jo",
    id: "0x865cf4d41e6ab539:0x6b3c01785d1919b4",
    niche: "Roofer",
    phone: "+12104542400",
    query: "San Antonio, Texas Roofer",
    rating: "4.7",
    reviews: "567",
    state: "Texas",
    street: "10537 Gulfdale St",
    title: "Roof Fix",
    website: "https://www.rooffixsa.com/?utm_source=google&utm_medium=gmb",
    zip: "78216",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["henry@shieldroofingsa.com"],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWgwdnlfMHEw",
    id: "0x865c61ddb15d8d1f:0xa598e88333efd074",
    niche: "Roofer",
    phone: "+12107614487",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "99",
    state: "Texas",
    street: "21750 Hardy Oak Blvd STE 102-110",
    title: "Shield Roofing Services",
    website:
      "https://shieldroofingonline.com/?utm_source=gmb&utm_medium=referral",
    zip: "78258",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["info@integritysa.com"],
    gmb: "https://www.google.com/localservices/profile?spp=CgsvZy8xdGo1ODV0bg%3D%3D",
    id: "0x865c60424cba12f1:0xf23b8f3538efa51e",
    niche: "Roofer",
    phone: "+12103407663",
    query: "San Antonio, Texas Roofer",
    rating: "4.8",
    reviews: "250",
    state: "Texas",
    street: "4807 West Ave",
    title: "Integrity Roofing & Siding",
    website: "https://integritysa.com/",
    zip: "78213",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["calvin.chhor@alphamediausa.com"],
    gmb: "https://www.google.com/localservices/profile?spp=CgsvZy8xdGRiZnZsdg%3D%3D",
    id: "0x865c8a0d7c8b6afd:0x754a19ec6e167121",
    niche: "Roofer",
    phone: "",
    query: "San Antonio, Texas Roofer",
    rating: "4.5",
    reviews: "478",
    state: "Texas",
    street: "11803 Warfield St",
    title: "Stephens Roofing & Remodeling",
    website: "https://www.stephensroofing.net/",
    zip: "78216",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWR6dnQ2X3Q0",
    id: "0x865c43a1ca59c39b:0x9dfbe70129f19089",
    niche: "Roofer",
    phone: "+12109038139",
    query: "San Antonio, Texas Roofer",
    rating: "4.9",
    reviews: "203",
    state: "Texas",
    street: "1999 Gulfmart St #517",
    title: "Rox Roofing",
    website:
      "https://roxroofing.com/?utm_source=google&utm_medium=organic&utm_campaign=gmb",
    zip: "78217",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["office@krestaroofing.com"],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWJ0cnNrdnBw",
    id: "0x865c5f95f076666f:0xc6e19d5d44ae2b67",
    niche: "Roofer",
    phone: "+12109349604",
    query: "San Antonio, Texas Roofer",
    rating: "4.9",
    reviews: "156",
    state: "Texas",
    street: "119 E Turbo Dr #200",
    title: "Kresta Roofing",
    website: "https://krestaroofing.com/roofing-contractor-san-antonio-tx/",
    zip: "78216",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["info@bisonroofing.net", "20info@bison.net"],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWNseTVkeXMw",
    id: "0x865c607452798839:0xdf86b4edf90a754f",
    niche: "Roofer",
    phone: "+12108889646",
    query: "San Antonio, Texas Roofer",
    rating: "4.9",
    reviews: "289",
    state: "Texas",
    street: "1441 Austin Hwy",
    title: "Bison Roofing",
    website: "http://www.bisonroofing.net/",
    zip: "78209",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: [
      "contact@roofingservicesnow.Com",
      "contact@RoofingServicesNow.Com",
    ],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWMxeGp5ZHd6",
    id: "0x865cf2544e565897:0xd3e281ebfacf02ab",
    niche: "Roofer",
    phone: "+12109880188",
    query: "San Antonio, Texas Roofer",
    rating: "4.9",
    reviews: "79",
    state: "Texas",
    street: "1777 NE Interstate 410 Loop Suite 600",
    title:
      "Roofing Services Now: Roof Leak Repair and Installation San Antonio",
    website: "http://www.roofingservicesnow.com/",
    zip: "78217",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWJ6ejI2dzgw",
    id: "0x865c602546b305dd:0x703bb1bb0f823fdf",
    niche: "Roofer",
    phone: "+12108995600",
    query: "San Antonio, Texas Roofer",
    rating: "4.9",
    reviews: "108",
    state: "Texas",
    street: "1231 Safari",
    title: "Presidio Roofing Company of San Antonio",
    website: "https://presidioroof.com/",
    zip: "78216",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWdjM3czbHNx",
    id: "0x865c5f958fb1f16f:0xcb9c5d1126c1962a",
    niche: "Roofer",
    phone: "+12103614013",
    query: "San Antonio, Texas Roofer",
    rating: "4.8",
    reviews: "87",
    state: "Texas",
    street: "119 Bobby Lou",
    title: "Two Brothers Roofing",
    website: "http://twoprobros.com/",
    zip: "78218",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["cjsroofing.llc@gmail.com"],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWNrdnN3NnN5",
    id: "0x865cf35de1bdf113:0x8844223fc2514c43",
    niche: "Roofer",
    phone: "+12102013687",
    query: "San Antonio, Texas Roofer",
    rating: "4.8",
    reviews: "136",
    state: "Texas",
    street: "114 N Ellison Dr Ste 110",
    title: "CJ's Roofing, LLC",
    website: "https://www.cjsroofingllc.com/",
    zip: "78251",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: [
      "example@email.com",
      "20info@rhinoroofers.com",
      "info@rhinoroofers.com",
    ],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXExZ2poOWM0",
    id: "0x865c671550653bc3:0x87fd448cd8c05da5",
    niche: "Roofer",
    phone: "+12107562834",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "692",
    state: "Texas",
    street: "4949 N Loop 1604 W Suite 250",
    title: "Rhino Roofers: San Antonio Roofing Company",
    website: "https://rhinoroofers.com/",
    zip: "78249",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["info@arproofing.com"],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWcwamRxOXpr",
    id: "0x865c6184ed4172d7:0x1ddb9b857a610191",
    niche: "Roofer",
    phone: "+12107140040",
    query: "San Antonio, Texas Roofer",
    rating: "4.9",
    reviews: "142",
    state: "Texas",
    street: "1100 Northwest Loop 410 #700",
    title: "ARP Roofing & Remodeling",
    website:
      "https://arproofing.com/san-antonio-roofing-contractor/?utm_source=GBP",
    zip: "78213",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["rolands.roofing1970@gmail.com"],
    gmb: "https://www.google.com/localservices/profile?spp=CgsvZy8xdGdtMzhteA%3D%3D",
    id: "0x865c5d5cda2ff38b:0x831aeda4e6d50b80",
    niche: "Roofer",
    phone: "+12105437663",
    query: "San Antonio, Texas Roofer",
    rating: "4.6",
    reviews: "143",
    state: "Texas",
    street: "6735 Low Bid Ln A",
    title: "Roland's Roofing Co. Inc.",
    website: "https://rolandsroofing.com/",
    zip: "78250",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWdtenZqZHZ6",
    id: "0x865c8b681f2b7171:0x4e08cfb704fecc29",
    niche: "Roofer",
    phone: "+12108076767",
    query: "San Antonio, Texas Roofer",
    rating: "4.8",
    reviews: "172",
    state: "Texas",
    street: "18911 Hardy Oak Blvd Suite 166",
    title: "Atrium Roofing",
    website: "https://www.atriumroofing.com/",
    zip: "78258",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=CgwvZy8xeWZqcnpjejE%3D",
    id: "0x865c6202f3dce985:0xba1fbc5f7b97bad4",
    niche: "Roofer",
    phone: "+12108676001",
    query: "San Antonio, Texas Roofer",
    rating: "4.8",
    reviews: "154",
    state: "Texas",
    street: "18756 Stone Oak Pkwy STE. 200",
    title: "Premier Roofing and Contracting",
    website: "https://www.premierroofingandcontracting.com/",
    zip: "78258",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=CgsvZy8xdGtrdjh5cw%3D%3D",
    id: "0x865c5fd5401a696d:0xf0aa2602ed7d82ff",
    niche: "Roofer",
    phone: "+18338096886",
    query: "San Antonio, Texas Roofer",
    rating: "4.7",
    reviews: "942",
    state: "Texas",
    street: "5039 West Ave",
    title: "BELDON Roofing Company",
    website: "https://www.beldon.com/",
    zip: "78213",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["info@mmroofsiding.com"],
    gmb: "https://www.google.com/localservices/profile?spp=CgsvZy8xdGh6djZqXw%3D%3D",
    id: "0x865c5ed175da3345:0x40ebd255b556f852",
    niche: "Roofer",
    phone: "+12108226070",
    query: "San Antonio, Texas Roofer",
    rating: "4.8",
    reviews: "153",
    state: "Texas",
    street: "16601 Blanco Rd Suite 222",
    title: "M&M Roofing, Siding & Windows - San Antonio",
    website: "https://mmroofsiding.com/san-antonio-roofing-company-near-me/",
    zip: "78232",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["michael@idealroofer.com", "adamsmith@company.com"],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWZ4OWtwOTV3",
    id: "0x865c6200745c8a61:0x3bb0906a49f9e18f",
    niche: "Roofer",
    phone: "+12104851553",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "251",
    state: "Texas",
    street: "10634 Gulfdale St Suite 1",
    title: "Ideal Precision Roofing & Exteriors LLC",
    website: "http://www.idealprecisionroofing.com/",
    zip: "78216",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["rodroofing@sbcglobal.net", "your@email.com"],
    gmb: "https://www.google.com/localservices/profile?spp=CgsvZy8xdGQwMHhteQ%3D%3D",
    id: "0x865c5f395ae2f93f:0x1eef161a1b6e2860",
    niche: "Roofer",
    phone: "+12107348444",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "70",
    state: "Texas",
    street: "440 Menchaca St",
    title: "Rod Roofing, LLC",
    website: "http://www.rodroofingsa.com/",
    zip: "78207",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["rodroofing@sbcglobal.net", "your@email.com"],
    gmb: "https://www.google.com/localservices/profile?spp=CgsvZy8xdGQwMHhteQ%3D%3D",
    id: "0x865c5f395ae2f93f:0x1eef161a1b6e2860",
    niche: "Roofer",
    phone: "+12107348444",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "70",
    state: "Texas",
    street: "440 Menchaca St",
    title: "Rod Roofing, LLC",
    website: "http://www.rodroofingsa.com/",
    zip: "78207",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["betterroofingusainc@yahoo.com"],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWMxcGtyanY0",
    id: "0x865cf653a902eadd:0xad7c4f803944ecf0",
    niche: "Roofer",
    phone: "+12109718684",
    query: "San Antonio, Texas Roofer",
    rating: "4.6",
    reviews: "209",
    state: "Texas",
    street: "2530 E Southcross",
    title: "Better Roofing USA, Inc.",
    website: "http://www.betterroofingusainc.com/",
    zip: "78223",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["tiger@santexroofing.com"],
    gmb: "https://www.google.com/localservices/profile?spp=CgwvZy8xMmNuenMxNG0%3D",
    id: "0x865c687bda052df3:0x9639b10dc22cfa96",
    niche: "Roofer",
    phone: "+12105209487",
    query: "San Antonio, Texas Roofer",
    rating: "4.8",
    reviews: "118",
    state: "Texas",
    street: "10527 Stone Creek Pl",
    title: "Santex Roofing",
    website: "http://santexroofing.com/",
    zip: "78254",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["eben@eyebytes.com"],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWYzMjFzMXJ5",
    id: "0x865c5f3337f19abb:0x30c80f3f18359fd8",
    niche: "Roofer",
    phone: "+12106834721",
    query: "San Antonio, Texas Roofer",
    rating: "4.9",
    reviews: "148",
    state: "Texas",
    street: "5504 Bandera Rd #308",
    title: "Heaven Sent Roofing LLC",
    website: "http://www.heavensentroofing.com/",
    zip: "78238",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["info@mazehome.com"],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWhjel81eXh6",
    id: "0x865c43b86af40001:0x20237bddef2462f3",
    niche: "Roofer",
    phone: "+12103007183",
    query: "San Antonio, Texas Roofer",
    rating: "4.7",
    reviews: "86",
    state: "Texas",
    street: "7715 Mainland Dr Suite 114",
    title: "Roesler's Roofing and Remodeling",
    website: "http://www.roeslersroofingandremodeling.com/",
    zip: "78250",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["name@example.com", "info@topnotchroofingtx.com"],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWduMTFjbjVt",
    id: "0x865c6923eeca9141:0x24957241c29e8d35",
    niche: "Roofer",
    phone: "+12107807629",
    query: "San Antonio, Texas Roofer",
    rating: "4.9",
    reviews: "46",
    state: "Texas",
    street: "",
    title: "Top Notch Roofing - San Antonio",
    website: "https://topnotchroofingtx.com/",
    zip: "",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWI2ajNsY3M1",
    id: "0x865cf4e292711e83:0xacb76d6ef26cfff8",
    niche: "Roofer",
    phone: "+12107961096",
    query: "San Antonio, Texas Roofer",
    rating: "4.9",
    reviews: "1,054",
    state: "Texas",
    street: "900 Isom Rd Suite 305",
    title: "Bondoc Roofing",
    website:
      "https://bondocroofing.com/?utm_medium=Click&utm_source=GoogleMyBusiness",
    zip: "78216",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["robert@broofa.com"],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWdmY2ZqbTh6",
    id: "0x865c5ebc9ad354a3:0xcac9ad69950e99f4",
    niche: "Roofer",
    phone: "+12103960922",
    query: "San Antonio, Texas Roofer",
    rating: "4.7",
    reviews: "110",
    state: "Texas",
    street: "345 N San Gabriel Ave",
    title: "HG Roof Repair",
    website:
      "https://hg-roof-repair.business.site/?utm_source=gmb&utm_medium=referral",
    zip: "78237",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWYzOTZ0NGps",
    id: "0x865c430f44dbc8ed:0xff8c8df31c21c1f1",
    niche: "Roofer",
    phone: "+12106502090",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "183",
    state: "Texas",
    street: "9258 Culebra Rd",
    title: "Twin Hammers Roofing & Contracting",
    website: "https://www.twinhammersroofing.com/",
    zip: "78251",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXJrcHB5YzFk",
    id: "0xa4c3849d12becc35:0xf7ac0d4f7c01fa7a",
    niche: "Roofer",
    phone: "+12109349450",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "71",
    state: "Texas",
    street: "8535 Wurzbach Rd Suite 104",
    title: "Total Home Roofing",
    website:
      "https://www.throofing.com/san-antonio.html?utm_source=google&utm_medium=organic&utm_campaign=gmb-san-antonio&utm_content=website",
    zip: "78240",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["20info@usahome-roof.com", "info@usahome-roof.com"],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXA2d21faF84",
    id: "0x865c61cc47619315:0xa8722b3d84134e45",
    niche: "Roofer",
    phone: "+12109358444",
    query: "San Antonio, Texas Roofer",
    rating: "4.9",
    reviews: "63",
    state: "Texas",
    street: "10221 Desert Sands St Suite204",
    title: "USA Home Roofing & Exteriors",
    website: "https://usahome-roof.com/",
    zip: "78216",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["info@kiddroof.com"],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWJ4MXlxY3Bf",
    id: "0x865c8ada84b61d1b:0x66cddcf19d546c79",
    niche: "Roofer",
    phone: "+12108328241",
    query: "San Antonio, Texas Roofer",
    rating: "4.8",
    reviews: "187",
    state: "Texas",
    street: "2415 Boardwalk St",
    title: "Kidd Roofing",
    website:
      "https://www.kiddroof.com/san-antonio-roofing/?utm_source=gmb&utm_medium=organic&utm_campaign=san-antonio",
    zip: "78217",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXA5bWxseDli",
    id: "0x865cf5e98b185027:0xb7d2bfdcad1efdc9",
    niche: "Roofer",
    phone: "+12109427487",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "25",
    state: "Texas",
    street: "4040 Broadway Suite 240-318",
    title: "San Antonio Commercial Roofing",
    website: "https://sanantoniocommercialroofingtx.com/",
    zip: "78209",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: [
      "matt@pixelspread.com",
      "impallari@gmail.com",
      "hello@rfuenzalida.com",
    ],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWM0a3dzbm1r",
    id: "0x865c88fab3aa59bb:0xbe2a950772f27d0f",
    niche: "Roofer",
    phone: "+12108519125",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "62",
    state: "Texas",
    street: "2411 Verona Park",
    title: "Reliant Roofing",
    website: "https://reliantroofingtx.com/",
    zip: "78260",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: [
      "office@priorityroofs.com",
      "houston@priorityroofs.com",
      "sanantonio@priorityroofs.com",
      "austin@priorityroofs.com",
      "tyler@priorityroofs.com",
      "colorado@priorityroofs.com",
      "teresa@priorityroofs.com",
      "sandiego@priorityroofs.com",
      "okc@priorityroofs.com",
      "ftworth@priorityroofs.com",
      "kc@priorityroofs.com",
      "lakeland@priorityroofs.com",
      "orlando@priorityroofs.com",
      "contact@priorityroofs.com",
    ],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXNtdnBidHc0",
    id: "0x865c61ee477ef943:0xf89ff081a4d89460",
    niche: "Roofer",
    phone: "+12107933028",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "182",
    state: "Texas",
    street: "7254 Blanco Rd #100",
    title: "Priority Roofing",
    website: "https://priorityroofs.com/",
    zip: "78216",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMW54cXh6c3h6",
    id: "0x865c6328d994c695:0x55c9558bf9e35e28",
    niche: "Roofer",
    phone: "+12109267663",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "119",
    state: "Texas",
    street: "18343 Muir Glen Dr",
    title: "Klein Roofing",
    website: "http://www.kleinroofing.com/sanantonio",
    zip: "78257",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["info@alamoroofing.com"],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXQzMjQ0X213",
    id: "0x865c8b9d3024994d:0x20fdb0ccd9ce8e4c",
    niche: "Roofer",
    phone: "+12107074342",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "317",
    state: "Texas",
    street: "12045 Starcrest Dr",
    title: "Alamo Roofing",
    website: "http://alamoroofing.com/",
    zip: "78247",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["info@neriroofing.com"],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWgwODFxc3p2",
    id: "0x865cf71bfb28196b:0x1e5b2e551b6bd457",
    niche: "Roofer",
    phone: "+12102648884",
    query: "San Antonio, Texas Roofer",
    rating: "4.7",
    reviews: "36",
    state: "Texas",
    street: "355 Kendalia Ave",
    title: "Neri Roofing",
    website: "https://neriroofing.com/",
    zip: "78214",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["bobby@bhroofingsa.com"],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMW5zaDFxczAw",
    id: "0x865c8b2c9d24c9cb:0xcbfc83254f41cfb2",
    niche: "Roofer",
    phone: "+12102969207",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "240",
    state: "Texas",
    street: "14093 Bulverde Rd",
    title: "BH Roofing - Your Roofing Heroes",
    website: "https://bhroofingsa.com/",
    zip: "78247",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=CgsvZy8xdGdkY3Y5ag%3D%3D",
    id: "0x865c6671cb400a8f:0x71b6498ef5f55e57",
    niche: "Roofer",
    phone: "+12106979835",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "60",
    state: "Texas",
    street: "15603 Babcock Rd",
    title: "Norwest Roofing",
    website: "https://www.norwestroofing.com/",
    zip: "78255",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["daniel@cen-texroofing.com"],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXJmcmMzaDdi",
    id: "0x865c5d0e89fb761f:0xa5a23512b014ca44",
    niche: "Roofer",
    phone: "+12108440184",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "75",
    state: "Texas",
    street: "",
    title: "Cen-Tex Roofing & Renovations Inc.",
    website: "http://www.cen-texroofing.com/",
    zip: "",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["support@schedulista.com"],
    gmb: "https://www.google.com/localservices/profile?spp=CgsvZy8xdGwxajlsNg%3D%3D",
    id: "0x865c5d0d43af52a1:0xe866bff5fc608e37",
    niche: "Roofer",
    phone: "+12104407734",
    query: "San Antonio, Texas Roofer",
    rating: "4.4",
    reviews: "34",
    state: "Texas",
    street: "5612 Grissom Rd",
    title: "Discount Roofing Company",
    website: "https://www.discountroofingtx.org/",
    zip: "78238",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: [
      "repairs@quickroofing.com",
      "orders@quickroofing.com",
      "support@quickroofing.com",
    ],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWdnajc3aHBn",
    id: "0x865c8adac08de49d:0x203fc47282e3762d",
    niche: "Roofer",
    phone: "+12105217663",
    query: "San Antonio, Texas Roofer",
    rating: "4.7",
    reviews: "27",
    state: "Texas",
    street: "2503 Boardwalk St",
    title: "Quick Roofing",
    website: "http://www.quickroofing.com/san-antonio/",
    zip: "78217",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWd3bWxmZ2Jo",
    id: "0x865c8d753cc8b4f9:0xd22578c103c7d6d1",
    niche: "Roofer",
    phone: "+12103105543",
    query: "San Antonio, Texas Roofer",
    rating: "4.9",
    reviews: "126",
    state: "Texas",
    street: "18819 FM 2252 Building #8",
    title: "Rooftop Roofing and Remodeling LLC",
    website: "https://rooftoproofingandremodelingllc.com/",
    zip: "78266",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["info@antlerroofing.com"],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWM3MmtqcWI0",
    id: "0x865c61cbbf55477d:0xd3e552040a531b6c",
    niche: "Roofer",
    phone: "+12109986350",
    query: "San Antonio, Texas Roofer",
    rating: "4.9",
    reviews: "44",
    state: "Texas",
    street: "1100 Northwest Loop 410",
    title: "Antler Roofing LLC",
    website: "https://antlerroofing.com/",
    zip: "78213",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["info@oneplyroofingco.com"],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXJnODdqMl83",
    id: "0x865c5f7cd3877cf7:0x6355b884e507fdc4",
    niche: "Roofer",
    phone: "+12108121111",
    query: "San Antonio, Texas Roofer",
    rating: "4.8",
    reviews: "50",
    state: "Texas",
    street: "118 Camino Santa Maria",
    title: "One Ply Roofing Co.",
    website: "https://oneplyroofingco.com/",
    zip: "78228",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=CgwvZy8xamt3bDY3N2o%3D",
    id: "0x865c5a5bffffffff:0x56d3deea6c781207",
    niche: "Roofer",
    phone: "+12107824691",
    query: "San Antonio, Texas Roofer",
    rating: "4.9",
    reviews: "91",
    state: "Texas",
    street: "4068 I-35 Frontage Rd",
    title: "Discovery Roofing & Sheet Metal",
    website: "http://www.mydiscoveryroofing.com/",
    zip: "78219",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWMzMXZ5Znox",
    id: "0x865cf61480e5124d:0xfac89312ae94399f",
    niche: "Roofer",
    phone: "+12103940520",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "48",
    state: "Texas",
    street: "",
    title: "Murcia Roofing & Remodeling",
    website: "https://www.murciaroofingandremodelingtx.com/",
    zip: "",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXNuN3BkNXh6",
    id: "0x865c673bf9368575:0x394dc352c4158865",
    niche: "Roofer",
    phone: "+12107837663",
    query: "San Antonio, Texas Roofer",
    rating: "4.9",
    reviews: "33",
    state: "Texas",
    street: "8502 Huebner Rd STE 400",
    title: "Premier Roofing Company",
    website:
      "https://www.premier-roofing.com/locations/san-antonio-roofing/?utm_source=google&utm_medium=local&utm_campaign=GMB&utm_content=SanAntonio",
    zip: "78240",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["info@homeandroofing.com", "Info@homeandroofing.com"],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMW5manAzZHl5",
    id: "0x865c6bbbe3199265:0x2ee4a86fabff0779",
    niche: "Roofer",
    phone: "+12108957045",
    query: "San Antonio, Texas Roofer",
    rating: "4.8",
    reviews: "21",
    state: "Texas",
    street: "14532 Nautico Dr",
    title: "Defense Home & Roofing LLC",
    website: "https://www.homeandroofing.com/",
    zip: "78254",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["michael@tower-roofing.com"],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWYwM3J3NjU5",
    id: "0x865c5d3890a51a85:0x85b782e0b605cab4",
    niche: "Roofer",
    phone: "+12103130168",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "36",
    state: "Texas",
    street: "",
    title: "Tower Roofing",
    website: "https://tower-roofing.com/",
    zip: "",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWZtdjJxdm5q",
    id: "0x865c673ca3dc8829:0xc8f8f805f6af950a",
    niche: "Roofer",
    phone: "+12103618465",
    query: "San Antonio, Texas Roofer",
    rating: "4.9",
    reviews: "33",
    state: "Texas",
    street: "5828 Sebastian Pl Suite 102",
    title: "McAllen Valley Roofing Co.",
    website: "https://www.sanantonioroofexperts.com/",
    zip: "78249",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXNxamQ0ajhn",
    id: "0x865c61276c7f9ec3:0x538b2df23296f616",
    niche: "Roofer",
    phone: "+12107297663",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "39",
    state: "Texas",
    street: "1100 Northwest Loop 410 # 700",
    title: "Elite Roofing LLC",
    website:
      "https://www.eliteroofpro.com/areas-we-serve/leading-roofer-in-san-antonio",
    zip: "78213",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["Info@Design-Roofing.com"],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXBkXzlrM3Zx",
    id: "0x865c616400c37841:0x78884b931051a6fa",
    niche: "Roofer",
    phone: "+12107948402",
    query: "San Antonio, Texas Roofer",
    rating: "4.8",
    reviews: "99",
    state: "Texas",
    street: "12227 Huebner Rd Suite 107",
    title: "Design Roofing & General Contractors",
    website: "http://www.design-roofing.com/",
    zip: "78230",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["admin@pa-ru.com"],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWZ4N3R2bTc1",
    id: "0x865c434bc6288eaf:0xa8ed420fcc745100",
    niche: "Roofer",
    phone: "+12104590000",
    query: "San Antonio, Texas Roofer",
    rating: "4.9",
    reviews: "132",
    state: "Texas",
    street: "12000 Crownpoint Dr Ste 110",
    title: "PaRu Roofing and Construction",
    website: "https://www.paruconstruction.com/",
    zip: "78233",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWI4ejZjNV9s",
    id: "0x865c627c0e916887:0xf1ad42b4f7d3e726",
    niche: "Roofer",
    phone: "+12108638856",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "26",
    state: "Texas",
    street: "",
    title: "Mancha Roofing",
    website: "http://www.mancharoofing.com/",
    zip: "",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=CgsvZy8xdHo5NjRfaw%3D%3D",
    id: "0x865c8b5666670d45:0xfd017decb746fc5f",
    niche: "Roofer",
    phone: "+12106377663",
    query: "San Antonio, Texas Roofer",
    rating: "4.6",
    reviews: "32",
    state: "Texas",
    street: "4733 Whirlwind Dr",
    title: "Lone Star Roofing and Construction, Inc.",
    website: "https://lonestarroofing.com/",
    zip: "78217",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWI2bm1sdjdo",
    id: "0x865c8bba87558b81:0x481581b4ed9c4a6",
    niche: "Roofer",
    phone: "+12102717663",
    query: "San Antonio, Texas Roofer",
    rating: "4.5",
    reviews: "44",
    state: "Texas",
    street: "11103 San Pedro Ave #120",
    title: "Ja-Mar Roofing & Sheet Metal - San Antonio",
    website: "https://jamarroofing.com/san-antonio-roofers/",
    zip: "78216",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["jimmy@jimmysroofingllc.com"],
    gmb: "https://www.google.com/localservices/profile?spp=CgwvZy8xMjYxMmN6ODU%3D",
    id: "0x865c5a65faa7f667:0x2c93c3f306a734fc",
    niche: "Roofer",
    phone: "+12106320759",
    query: "San Antonio, Texas Roofer",
    rating: "4.4",
    reviews: "21",
    state: "Texas",
    street: "9915 Roosevelt Ave",
    title: "Jimmy's Roofing LLC",
    website: "https://jimmysroofingllc.com/",
    zip: "78214",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["alexandersroofing@live.com", "your@email.com"],
    gmb: "https://www.google.com/localservices/profile?spp=CgsvZy8xdmtucHRjMQ%3D%3D",
    id: "0x865c5c2c9987bf01:0xbb6f2159dd4594ce",
    niche: "Roofer",
    phone: "+12104361135",
    query: "San Antonio, Texas Roofer",
    rating: "4.4",
    reviews: "30",
    state: "Texas",
    street: "350 W Broadview Dr",
    title: "Alexanders Roofing",
    website: "http://www.alexandersroofing.com/",
    zip: "78228",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["brian@andersonroofrepairs.com"],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWo4XzdzN3lq",
    id: "0x865c8bc650340a6d:0x104a624c781a69e4",
    niche: "Roofer",
    phone: "+12106997663",
    query: "San Antonio, Texas Roofer",
    rating: "4.8",
    reviews: "66",
    state: "Texas",
    street: "",
    title: "Anderson Roofing and Repairs",
    website: "https://www.andersonroofrepairs.com/",
    zip: "",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWJ0d3RqdmJ5",
    id: "0x865c8ada95f91bfd:0x38d2d085ca2d9ad7",
    niche: "Roofer",
    phone: "+12102900431",
    query: "San Antonio, Texas Roofer",
    rating: "4.7",
    reviews: "23",
    state: "Texas",
    street: "7919 Jethro Ln",
    title: "Advanced Roofing Solutions",
    website: "http://www.metalroofinginsanantoniotx.com/",
    zip: "78266",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["1stchoiceroofingtx@gmail.com"],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWI2cDEyOHAw",
    id: "0x865c67d70f352d0d:0x48ad68ed3eb65fc9",
    niche: "Roofer",
    phone: "+17266107663",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "34",
    state: "Texas",
    street: "",
    title: "1st Choice Roofing",
    website: "http://www.1stchoiceroofingtx.com/",
    zip: "",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["info@valor-roofing.com", "you@domain.com"],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXFua2tmNWpx",
    id: "0x865c6728cc202621:0xb55599891c24a653",
    niche: "Roofer",
    phone: "+18883136354",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "29",
    state: "Texas",
    street: "2810 Babcock Rd",
    title: "Valor Roofing & Restoration",
    website: "http://www.valor-roofing.com/",
    zip: "78240",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["jettroofingllc@gmail.com", "name@example.com"],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXJrZHpwemx6",
    id: "0x8bf13fc449faeb1b:0x691f557a3f3ab6aa",
    niche: "Roofer",
    phone: "+12109082965",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "27",
    state: "Texas",
    street: "",
    title: "Jett Roofing & Exteriors",
    website: "https://www.jettroofingexteriors.com/",
    zip: "",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["info@greenproroofing.com"],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWYzbjR4anlr",
    id: "0x865c6786bd27f0a7:0x9697912d4397d0d6",
    niche: "Roofer",
    phone: "+12104001600",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "26",
    state: "Texas",
    street: "3525 West Ave #202",
    title: "Green Pro Roofing & Renovations",
    website: "https://www.greenproroofing.com/",
    zip: "78213",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["info@weathertechtx.com"],
    gmb: "https://www.google.com/localservices/profile?spp=CgwvZy8xcTVibnl2MDE%3D",
    id: "0x865c8d24a0c3815d:0x93adf7b9b834adf5",
    niche: "Roofer",
    phone: "+12108809962",
    query: "San Antonio, Texas Roofer",
    rating: "4.6",
    reviews: "27",
    state: "Texas",
    street: "11509 Forest Br",
    title: "WeatherTech Roofing",
    website: "https://weathertechtx.com/",
    zip: "78233",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXNnNXEzY3Nm",
    id: "0x865ca3aafb8d2fad:0xdc3d0429c3f39205",
    niche: "Roofer",
    phone: "",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "43",
    state: "Texas",
    street: "4702 Broom St",
    title: "Agave Roofing",
    website: "https://www.agave-roofing.com/",
    zip: "78217",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["info@RHRRoofing.com"],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMW15ODZyOGRi",
    id: "0x865c9176242406b5:0x52f21b524253c0ed",
    niche: "Roofer",
    phone: "+12104206640",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "52",
    state: "Texas",
    street: "",
    title: "RHR Roofing",
    website: "https://rhrroofing.com/",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWg1NjEydmhi",
    id: "0x865c7d2d20c65dd1:0x7b9d3617ec6d44c8",
    niche: "Roofer",
    phone: "+12105571451",
    query: "San Antonio, Texas Roofer",
    rating: "4.7",
    reviews: "24",
    state: "Texas",
    street: "",
    title: "Bear Roofing Systems",
    website: "",
    zip: "",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["info@eliteroofingsolutions.com"],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWMxbXp4Y2pk",
    id: "0x865c8a91e8ae2575:0x5ce5c14bffc1ba24",
    niche: "Roofer",
    phone: "+12104477812",
    query: "San Antonio, Texas Roofer",
    rating: "4.8",
    reviews: "27",
    state: "Texas",
    street: "8802 Tradeway St",
    title: "Elite Roofing Solutions - San Antonio Roof Repair & Replacement",
    website: "https://eliteroofingsolutions.com/roofing-san-antonio/",
    zip: "78217",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["info@saroofco.com", "saroofco@saroofco.com"],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXQwdGxfanBk",
    id: "0x865c61b8eca2f47f:0x223edebc33004d14",
    niche: "Roofer",
    phone: "+12102014344",
    query: "San Antonio, Texas Roofer",
    rating: "4.5",
    reviews: "16",
    state: "Texas",
    street: "9310 Broadway Suit #203",
    title: "San Antonio Roof Company - Roofing Contractor",
    website: "https://sanantonioroofcompany.com/",
    zip: "78217",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXJmNzJ6d3Q4",
    id: "0x865cf4a49dc6aaab:0xd935df6d6984e21f",
    niche: "Roofer",
    phone: "+12104131180",
    query: "San Antonio, Texas Roofer",
    rating: "4.7",
    reviews: "15",
    state: "Texas",
    street: "18410 US-281 #109",
    title: "Crowne Roofing Of Texas",
    website:
      "https://crowneroofingoftexas.com/contact-crowne-roofing-of-texas-san-antonio/",
    zip: "78259",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=CgwvZy8xcHR6ODc1NzY%3D",
    id: "0x865c60f5c05fd829:0x64a1e6b849f6e622",
    niche: "Roofer",
    phone: "+12107601230",
    query: "San Antonio, Texas Roofer",
    rating: "4.9",
    reviews: "95",
    state: "Texas",
    street: "1846 N Loop 1604 W Ste 205",
    title: "Rio Blanco Roofing & Restoration",
    website: "",
    zip: "78248",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWZuY3F3czk2",
    id: "0x865c65e5764fee35:0x15bdff44b5d60f3c",
    niche: "Roofer",
    phone: "+12106531776",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "30",
    state: "Texas",
    street: "8250 Cruiseship Bay",
    title: "Roof 1 Construction",
    website: "http://www.roof1construction.com/",
    zip: "78255",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWc5a2Z3bWJk",
    id: "0x865c623b47e7a095:0xddd275473ecac2d4",
    niche: "Roofer",
    phone: "+12105333757",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "11",
    state: "Texas",
    street: "19179 Blanco Rd #105",
    title: "Transcendent Roofing of San Antonio",
    website: "https://transcendentroofingsa.com/",
    zip: "78258",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=CgwvZy8xaGhscXozODc%3D",
    id: "0x865c420841bea263:0x7b904082acb5a678",
    niche: "Roofer",
    phone: "+12106392976",
    query: "San Antonio, Texas Roofer",
    rating: "4.8",
    reviews: "21",
    state: "Texas",
    street: "",
    title: "Regency Roofing & Remodeling",
    website: "",
    zip: "",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["info@onpointpremier.com", "john@smith.com"],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWg4YnI0NmQ1",
    id: "0x865c896a0f8f543d:0x62936373b606faca",
    niche: "Roofer",
    phone: "+12103010135",
    query: "San Antonio, Texas Roofer",
    rating: "4.6",
    reviews: "39",
    state: "Texas",
    street: "",
    title: "OnPoint Premier Roofing",
    website: "http://onpointpremierroofing.com/",
    zip: "",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=CgsvZy8xdGRsYzQyYw%3D%3D",
    id: "0x865c6030b60610cf:0x9c13443c366757b0",
    niche: "Roofer",
    phone: "+12103669484",
    query: "San Antonio, Texas Roofer",
    rating: "3.6",
    reviews: "107",
    state: "Texas",
    street: "434 Breesport St",
    title: "Cloud Roofing Company",
    website:
      "https://cloudroofing.com/?utm_source=GMB&utm_medium=organic&utm_campaign=GMB",
    zip: "78216",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["robert@broofa.com"],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWZucnZ6NTNz",
    id: "0x865cf51230f4f861:0x8d4caa2258d49bda",
    niche: "Roofer",
    phone: "+12105048704",
    query: "San Antonio, Texas Roofer",
    rating: "4.5",
    reviews: "11",
    state: "Texas",
    street: "213 Broadway",
    title: "Save A Roof of San Antonio",
    website: "https://sanantonio-roofing-company-sanantonio.com/",
    zip: "78205",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWNzNmQ4d3c0",
    id: "0x865c65c25f859231:0xb1b9274c2f6ba471",
    niche: "Roofer",
    phone: "+12105050070",
    query: "San Antonio, Texas Roofer",
    rating: "4.8",
    reviews: "75",
    state: "Texas",
    street: "",
    title: "Excel Roofing & Contracting",
    website: "http://www.excelroofingtexas.com/",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=CgsvZy8xdHBiY2Rkeg%3D%3D",
    id: "0x865c5b7883f2fb0f:0x97a3bad166ff4991",
    niche: "Roofer",
    phone: "+12103258049",
    query: "San Antonio, Texas Roofer",
    rating: "4.8",
    reviews: "31",
    state: "Texas",
    street: "",
    title: "Ware Roofing & Construction",
    website: "",
    zip: "",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWpzbDRsMGt3",
    id: "0x69e8938579b438d:0xa63eb49fb4c8ee8c",
    niche: "Roofer",
    phone: "+12109005793",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "281",
    state: "Texas",
    street: "13045 Maestro Spark",
    title: "Safepro Roofing",
    website: "https://safeproroofing.com/",
    zip: "78252",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["office.magnussatx@gmail.com"],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWoyeTR2Yzkz",
    id: "0x865c6152109cb327:0xd95ca10c16388948",
    niche: "Roofer",
    phone: "+12104298843",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "47",
    state: "Texas",
    street: "",
    title: "Magnus Exteriors",
    website: "http://magnusexteriors.com/",
    zip: "",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["powerroofingtx@hotmail.com"],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWRkeWsyZ2Iw",
    id: "0x865c5789f63a0563:0xb4d75cbc454bcce8",
    niche: "Roofer",
    phone: "+12104044885",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "23",
    state: "Texas",
    street: "423 Gillette Blvd",
    title: "Power Roofing & Carpentry",
    website: "https://www.powerroofingtx.com/",
    zip: "78221",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXN0eXAwNDZw",
    id: "0x865c61ca0e260cd1:0x1d367d7d8e9e7ce4",
    niche: "Roofer",
    phone: "+12105602877",
    query: "San Antonio, Texas Roofer",
    rating: "4.7",
    reviews: "14",
    state: "Texas",
    street: "8207 Callaghan Rd Suite 410",
    title: "Best Choice Roofing of San Antonio",
    website:
      "https://www.bestchoiceroofing.com/locations/texas/san-antonio/?utm_source=gmb&utm_medium=organic",
    zip: "78230",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["tonypechtxpro@gmail.com"],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMW54cGxqbHhu",
    id: "0xfbc97c0cb7e56ab:0xb9a94d2649b0abad",
    niche: "Roofer",
    phone: "+12106097073",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "33",
    state: "Texas",
    street: "4502 Avenida Prima St",
    title: "Texas Pro Roofing",
    website: "https://texasproroofing.net/",
    zip: "78233",
  },
  {
    city: "San Antonio",
    emailSync: true,
    emails: ["CLAIMS@NAILSROOFINGLLC.COM", "ARTUROVAL@NAILSROOFINGLLC.COM"],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWNzMHNkazg5",
    id: "0x865c5d0d237ba225:0x94d963be736fbc45",
    niche: "Roofer",
    phone: "+12103060854",
    query: "San Antonio, Texas Roofer",
    rating: "3.8",
    reviews: "38",
    state: "Texas",
    street: "2900 Mossrock # 110",
    title: "NAILS ROOFING LLC.",
    website: "http://www.nailsroofingllc.com/",
    zip: "78230",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=CgsvZy8xdGRwczI4Ng%3D%3D",
    id: "0x865c8ca2bd39b8bb:0xca933a3eb0f4b93c",
    niche: "Roofer",
    phone: "+12108226868",
    query: "San Antonio, Texas Roofer",
    rating: "4.0",
    reviews: "23",
    state: "Texas",
    street: "11919 Rail Dr #1",
    title: "Southwest Metal Roofing Systems",
    website: "http://www.swmetalroofing.com/",
    zip: "78233",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWZsMTh4ZDk3",
    id: "0x865c5f84895788ed:0xc00a4c2d1b2b0392",
    niche: "Roofer",
    phone: "+12105638800",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "14",
    state: "Texas",
    street: "700 N St Mary's St #1400",
    title: "Harbor Roofing - Metal Roofing",
    website: "http://www.harborroofing.construction/",
    zip: "78205",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=CgsvZy8xdGd5eDN3cw%3D%3D",
    id: "0x865cf604fe4e8709:0xeff8e203dfc8d3ba",
    niche: "Roofer",
    phone: "+12102245463",
    query: "San Antonio, Texas Roofer",
    rating: "4.1",
    reviews: "19",
    state: "Texas",
    street: "801 Wyoming St",
    title: "American Roofing & Metal Co. Inc.",
    website: "http://www.amerroofing.com/",
    zip: "78203",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWNuNXB3emgy",
    id: "0x865c6732ca2ff98b:0x316459eaba7a9851",
    niche: "Roofer",
    phone: "+12107222612",
    query: "San Antonio, Texas Roofer",
    rating: "4.6",
    reviews: "9",
    state: "Texas",
    street: "1777 NE Interstate 410 Loop Suite 600",
    title: "The Roofer Direct LLC.",
    website: "http://the-roofer-direct-llc.business.site/",
    zip: "78217",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWZjdDc2cWdq",
    id: "0x865c8cedd0815555:0x742078615182ebe5",
    niche: "Roofer",
    phone: "+12107720833",
    query: "San Antonio, Texas Roofer",
    rating: "4.7",
    reviews: "26",
    state: "Texas",
    street: "10855 I-35",
    title: "tm's quality metal roofing",
    website: "https://tmsqualitymetalroofing.com/",
    zip: "78233",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXJ3bGJsN2Nu",
    id: "0x865c61702a6fb143:0x5bd49edccff47608",
    niche: "Roofer",
    phone: "+12107497746",
    query: "San Antonio, Texas Roofer",
    rating: "4.2",
    reviews: "31",
    state: "Texas",
    street: "200 W Rhapsody Dr",
    title: "Tarrant Roofing",
    website:
      "https://tarrantroofing.com/san-antonio-tx-roofing-services/roofing-company-san-antonio/",
    zip: "78216",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMW4wN2JqZGxk",
    id: "0x865c5d4fa6303759:0xaa9c57f2da46b946",
    niche: "Roofer",
    phone: "+12108915533",
    query: "San Antonio, Texas Roofer",
    rating: "4.8",
    reviews: "18",
    state: "Texas",
    street: "",
    title: "Eagle Roofing & Contracting",
    website: "https://eagleroofco.com/",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWJfMnlwNHh2",
    id: "0x865c5e280437c345:0x3a30dc5389cfc72",
    niche: "Roofer",
    phone: "+12108386062",
    query: "San Antonio, Texas Roofer",
    rating: "4.2",
    reviews: "53",
    state: "Texas",
    street: "2819 Woodcliffe St #205",
    title: "Invision Roofing",
    website:
      "http://www.invisionroofing.com/?utm_source=google&utm_medium=organic&utm_campaign=gmb",
    zip: "78230",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWoyeGhnZzB6",
    id: "0x86412373f8645c35:0xd896173f972889ab",
    niche: "Roofer",
    phone: "+17133963951",
    query: "San Antonio, Texas Roofer",
    rating: "4.8",
    reviews: "16",
    state: "Texas",
    street: "6918 Scottswood",
    title: "LOA Roofing and Construction",
    website:
      "https://www.loaconstruction.com/san-antonio/?utm_source=GMBlisting&utm_medium=organic&utm_campaign=gmb-sanantonio",
    zip: "78239",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWc4djVnZHd3",
    id: "0x865c8acc1b9939cd:0xf31cebead2131155",
    niche: "Roofer",
    phone: "+12106805070",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "18",
    state: "Texas",
    street: "9310 Broadway #104",
    title: "Navarro Roofing Company",
    website: "https://navarrosroofing.com/",
    zip: "78217",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWtjOHZ5eWR5",
    id: "0x865c8b1bf3c5808b:0xf0da40b6dae9e03b",
    niche: "Roofer",
    phone: "+12105297226",
    query: "San Antonio, Texas Roofer",
    rating: "4.5",
    reviews: "22",
    state: "Texas",
    street: "1235 Safari",
    title: "Erie Home",
    website: "https://www.eriehome.com/",
    zip: "78216",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=CgwvZy8xcHAydm13ZG4%3D",
    id: "0x865c6814094790c1:0xef9a06045093aaaf",
    niche: "Roofer",
    phone: "+12106435671",
    query: "San Antonio, Texas Roofer",
    rating: "4.5",
    reviews: "40",
    state: "Texas",
    street: "10650 Culebra Rd #104-525",
    title: "C & C Roofing and Restoration Inc.",
    website: "https://www.candcroof.com/",
    zip: "78251",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=CgsvZy8xdGY5bnBjcQ%3D%3D",
    id: "0x865c8990edaf1887:0x347c5a9c4739879b",
    niche: "Roofer",
    phone: "+12104019746",
    query: "San Antonio, Texas Roofer",
    rating: "4.4",
    reviews: "28",
    state: "Texas",
    street: "14726 Bulverde Rd Street 3",
    title: "SA Roofmasters",
    website: "http://www.saroofs.com/",
    zip: "78247",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=CgsvZy8xdGYzcDl5ZA%3D%3D",
    id: "0x865cfa27466c88cf:0x9a30cad3454fc9d",
    niche: "Roofer",
    phone: "+12106339309",
    query: "San Antonio, Texas Roofer",
    rating: "4.6",
    reviews: "11",
    state: "Texas",
    street: "",
    title: "HR Roofing Inc.",
    website: "http://hrroofinginc.com/",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXJqYnhkNjBi",
    id: "0x865c6124779d6695:0xf2959cfb029c902f",
    niche: "Roofer",
    phone: "+12109726419",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "5",
    state: "Texas",
    street: "307 Country Wood Dr",
    title: "The San Antonio Roofing & Remodeling Crew",
    website: "https://www.roofingremodelingsanantonio.com/",
    zip: "78216",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWMzMTAzaDFk",
    id: "0x865c5ea9bed470a9:0xa4c68ee707e2500f",
    niche: "Roofer",
    phone: "+12107788060",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "120",
    state: "Texas",
    street: "8711 Finlandia Gap",
    title: "American Homes Roofing",
    website: "http://www.americanhomesroofing.com/",
    zip: "78251",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWYzdmwzcTN2",
    id: "0x865c8a313005ce5b:0x2dacf1b118e2f2b7",
    niche: "Roofer",
    phone: "+12106436507",
    query: "San Antonio, Texas Roofer",
    rating: "4.2",
    reviews: "10",
    state: "Texas",
    street: "21750 Hardy Oak Blvd Suite 259",
    title: "A Roof 4 You",
    website: "https://www.aroof4you.com/",
    zip: "78258",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXR4aGRiMXQ1",
    id: "0x865c8b062231e911:0xbe3a79e5138758de",
    niche: "Roofer",
    phone: "+12106063799",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "28",
    state: "Texas",
    street: "12031 Starcrest Dr",
    title: "Lynx Roofing",
    website: "https://www.lynxroof.com/",
    zip: "78247",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWR6NXFsN24x",
    id: "0x865cf54563307fc5:0x14ec0ff490fafc95",
    niche: "Roofer",
    phone: "+18324057924",
    query: "San Antonio, Texas Roofer",
    rating: "4.6",
    reviews: "13",
    state: "Texas",
    street: "4679 Walzem Rd bldg 2",
    title: "Tridom Roofing LLC",
    website:
      "https://www.tridomroofing.com/roofing-san-antonio-texas/?utm_source=google&utm_medium=organic&utm_campaign=gbp&utm_content=san-antonio",
    zip: "78218",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWgxM2J0X2Jo",
    id: "0x865c6365dc03dd0f:0xe26055566de78c8e",
    niche: "Roofer",
    phone: "+12107291888",
    query: "San Antonio, Texas Roofer",
    rating: "4.9",
    reviews: "89",
    state: "Texas",
    street: "401 E Sonterra Blvd Suite 375",
    title: "Flagstone Roofing & Exteriors",
    website: "http://www.flagstoneroofing.com/",
    zip: "78258",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=CgsvZy8xdmc2djRxeA%3D%3D",
    id: "0x865c8605271aa071:0x80b5ac1ccdc6c021",
    niche: "Roofer",
    phone: "+12103441125",
    query: "San Antonio, Texas Roofer",
    rating: "4.4",
    reviews: "32",
    state: "Texas",
    street: "4235 Laurie Michelle Rd",
    title: "Fry Roofing Inc",
    website: "https://fryroofing.com/",
    zip: "78261",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=CgsvZy8xdGQzcnh5Yg%3D%3D",
    id: "0x865cf54fa0a030ef:0x61d1fe0dbdd7471d",
    niche: "Roofer",
    phone: "+12103817335",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "46",
    state: "Texas",
    street: "",
    title: "Forthman Roofing",
    website: "https://www.forthmanroofing.com/",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWg1cmdyZHRw",
    id: "0x865c61e04aaf534f:0xdf4f9d2b0ff4f8f5",
    niche: "Roofer",
    phone: "+12103726964",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "8",
    state: "Texas",
    street: "13300 Old Blanco Rd #100a",
    title: "Towery Roofing",
    website: "http://www.toweryroofing.com/",
    zip: "78216",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=CgsvZy8xdGNfNGo1bQ%3D%3D",
    id: "0x865cf54aa82d1b0d:0x4c0d365c012acf3e",
    niche: "Roofer",
    phone: "+12104222153",
    query: "San Antonio, Texas Roofer",
    rating: "3.9",
    reviews: "11",
    state: "Texas",
    street: "8546 Broadway",
    title: "Action Roofing & Remodeling",
    website: "http://www.actionroofingandremodeling.com/",
    zip: "78217",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWJ6d3J5ejhu",
    id: "0x865c606510fb8077:0xf7fa3a203b4e3013",
    niche: "Roofer",
    phone: "+12104780964",
    query: "San Antonio, Texas Roofer",
    rating: "4.4",
    reviews: "13",
    state: "Texas",
    street: "1931 NW Military Hwy #236",
    title: "Right Source Roofing & Construction",
    website: "https://rightsourceconstruction.com/",
    zip: "78213",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=CgsvZy8xdHcxZjdtbg%3D%3D",
    id: "0x865c5d5a78e73273:0xece2c2c9bfb79db2",
    niche: "Roofer",
    phone: "+12108256125",
    query: "San Antonio, Texas Roofer",
    rating: "4.6",
    reviews: "9",
    state: "Texas",
    street: "",
    title: "Decker Roofing Co Inc",
    website: "https://decker-roofing.com/",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=CgwvZy8xaGc0d3l3aDE%3D",
    id: "0x865c5df876c956f1:0x179ceaa4ddc08d49",
    niche: "Roofer",
    phone: "+12106930390",
    query: "San Antonio, Texas Roofer",
    rating: "4.5",
    reviews: "8",
    state: "Texas",
    street: "4835 Medical Dr #29361",
    title: "Roof Doctor GC Services",
    website: "http://roofdoctortx.com/",
    zip: "78229",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWxmY3A2d2N4",
    id: "0x865c5f72759aef9b:0xb2915ac265e8ff01",
    niche: "Roofer",
    phone: "+12102547178",
    query: "San Antonio, Texas Roofer",
    rating: "4.2",
    reviews: "10",
    state: "Texas",
    street: "",
    title: "Texas Girl Roofing and Construction Inc.",
    website: "",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=CgsvZy8xdGc1XzFmeA%3D%3D",
    id: "0x865c6211e54deb63:0x87ab0b2d32deada7",
    niche: "Roofer",
    phone: "+18304887707",
    query: "San Antonio, Texas Roofer",
    rating: "4.8",
    reviews: "20",
    state: "Texas",
    street: "19141 Stone Oak Pkwy #104",
    title: "RepairMyRoof4Less.com",
    website: "http://repairmyroof4less.com/",
    zip: "78258",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=CgwvZy8xMnFnZ2g5ZzI%3D",
    id: "0x865cf54aa9b67b05:0x8df752ab1cb5951e",
    niche: "Roofer",
    phone: "",
    query: "San Antonio, Texas Roofer",
    rating: "4.4",
    reviews: "13",
    state: "Texas",
    street: "4215 Sunshadow St",
    title: "Texas Star Roofing & Construction",
    website: "http://www.tsrcinc.com/",
    zip: "78217",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWY1NDkyemM0",
    id: "0x865c5e61a60bffff:0xc525c812e80b3548",
    niche: "Roofer",
    phone: "+12107408333",
    query: "San Antonio, Texas Roofer",
    rating: "4.9",
    reviews: "14",
    state: "Texas",
    street: "",
    title: "Umbrella Roofing and Construction",
    website: "http://www.umbrella6roofing.com/",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXQxaDNyemt5",
    id: "0x865c6029d790000f:0xbdc9b670421262e8",
    niche: "Roofer",
    phone: "+12104681603",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "51",
    state: "Texas",
    street: "70 NE Interstate 410 Loop #306",
    title: "Spartan Roofing and Siding",
    website: "http://spartancontractors.com/",
    zip: "78216",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWI2eGhjeHp4",
    id: "0x865d01ff47aa9ee5:0xa1513cd17caaa43a",
    niche: "Roofer",
    phone: "+12108498025",
    query: "San Antonio, Texas Roofer",
    rating: "4.7",
    reviews: "20",
    state: "Texas",
    street: "",
    title: "B&W Roofing and Construction",
    website: "",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWpyZ3Q3NGR3",
    id: "0x865c93aec5f19adf:0xfd47f8fb61e30d9a",
    niche: "Roofer",
    phone: "+12105852537",
    query: "San Antonio, Texas Roofer",
    rating: "4.9",
    reviews: "86",
    state: "Texas",
    street: "17321 Bell N Dr",
    title: "Walton Roofing",
    website:
      "https://waltonroofingtx.com/san-antonio/?utm_source=google&utm_medium=organic&utm_campaign=gmb",
    zip: "78154",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXRjY2RwXzQ5",
    id: "0x865c59b34728c5b7:0xebe195921998bce2",
    niche: "Roofer",
    phone: "+12108026906",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "6",
    state: "Texas",
    street: "521 Pruitt Ave",
    title: "Texas-RRR Roofing Remodeling Restoration",
    website: "http://www.texas-rrr.com/",
    zip: "78204",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWhnNHdxbHh0",
    id: "0x865c5dc8b47fbb51:0x3ea4627f61b0eaa3",
    niche: "Roofer",
    phone: "+12102552027",
    query: "San Antonio, Texas Roofer",
    rating: "4.6",
    reviews: "8",
    state: "Texas",
    street: "",
    title: "Texas Stag-Roofing Solutions SA",
    website: "https://www.txstag.com/",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWo5NTc3ems0",
    id: "0x86409d0c5b6ac075:0x882b0ea8c4d95b08",
    niche: "Roofer",
    phone: "+19792244727",
    query: "San Antonio, Texas Roofer",
    rating: "4.5",
    reviews: "8",
    state: "Texas",
    street: "",
    title: "Reveille Roofing",
    website: "https://jordan7676.wixsite.com/reveilleroofing",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWoyX3pjMHBj",
    id: "0x865c69c1246a8eb3:0x655f61c8eee8391",
    niche: "Roofer",
    phone: "+12105597058",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "35",
    state: "Texas",
    street: "",
    title: "Apache Construction Concepts, LLC",
    website: "http://www.apachecc.net/",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=CgsvZy8xeDZxMjU2aw%3D%3D",
    id: "0x865c6efb6097b511:0xaa06006fbebe6b96",
    niche: "Roofer",
    phone: "+12103729000",
    query: "San Antonio, Texas Roofer",
    rating: "4.8",
    reviews: "63",
    state: "Texas",
    street: "20124 High Bluff Rd LOT 2",
    title: "Soukup Roofing LLC",
    website: "http://www.soukuproofing.com/",
    zip: "78023",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWo4cDk3N2dt",
    id: "0x865c8ba3a995eb7d:0xe81492d132e9c689",
    niche: "Roofer",
    phone: "+12103057663",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "16",
    state: "Texas",
    street: "1717 N Loop 1604 E Ste 117",
    title: "Charger Roofing LLC",
    website: "http://www.charger-roofing.com/",
    zip: "78232",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=CgsvZy8xdGRuOTJseg%3D%3D",
    id: "0x865c5d265a287edd:0xf71f89959d592187",
    niche: "Roofer",
    phone: "+12103991700",
    query: "San Antonio, Texas Roofer",
    rating: "4.3",
    reviews: "9",
    state: "Texas",
    street: "",
    title: "JOSROD ROOFING",
    website: "",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWhkNm5wdnk1",
    id: "0x865c5fff3d7ad52b:0x2b35726e46a4bb22",
    niche: "Roofer",
    phone: "+12107672272",
    query: "San Antonio, Texas Roofer",
    rating: "4.8",
    reviews: "40",
    state: "Texas",
    street: "",
    title: "CASA Exteriors",
    website: "http://www.casaexteriors.com/",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWZtYmc2cjN6",
    id: "0x865c8d0d32a98035:0xec6ecc13f8afd77e",
    niche: "Roofer",
    phone: "+12108968038",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "15",
    state: "Texas",
    street: "",
    title: "Relief Roofing and Restoration",
    website: "http://reliefroofingsatx.com/",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWpnczI3Nnc2",
    id: "0x865c89b204751e25:0xd3daf5dcd2a2b96",
    niche: "Roofer",
    phone: "+12102691438",
    query: "San Antonio, Texas Roofer",
    rating: "4.8",
    reviews: "22",
    state: "Texas",
    street: "",
    title: "Informed Roofing & Restoration",
    website: "http://www.informedroofing.com/",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWJ6c2RobnF6",
    id: "0x865c89ffe1d79b7d:0xec539150b022161e",
    niche: "Roofer",
    phone: "+12107946865",
    query: "San Antonio, Texas Roofer",
    rating: "4.7",
    reviews: "68",
    state: "Texas",
    street: "227 N Loop 1604 E Suite",
    title: "Phoenix Exteriors",
    website: "https://www.phoenixexteriors.com/",
    zip: "Antonio",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWhtMjhkc2hz",
    id: "0x865c5bf2da8c672b:0x1d518caffe7af165",
    niche: "Roofer",
    phone: "+12107618900",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "13",
    state: "Texas",
    street: "2616 SW Loop 410",
    title: "Roofing Company in San Antonio | Excel Construction Group",
    website: "https://www.excelcg.com/locations/sanantonio/",
    zip: "78227",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=CgsvZy8xdjQxX3d0MA%3D%3D",
    id: "0x865c60a8a760cda7:0x939696616e91d00f",
    niche: "Roofer",
    phone: "+12106927700",
    query: "San Antonio, Texas Roofer",
    rating: "4.2",
    reviews: "12",
    state: "Texas",
    street: "4260 Dividend Rd",
    title: "Empire Roofing",
    website: "https://www.empireroofing.com/sanantonio",
    zip: "78219",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=CgwvZy8xMWgwX3p0em4%3D",
    id: "0x865c5e44e85853bb:0x768ad27c824a13a3",
    niche: "Roofer",
    phone: "+12107947464",
    query: "San Antonio, Texas Roofer",
    rating: "4.9",
    reviews: "308",
    state: "Texas",
    street: "4603 N Stahl Park",
    title: "Prime Seamless Gutters & Roofing | Metal Roofing Contractor",
    website:
      "https://www.primeseamless.com/?utm_source=google&utm_medium=organic&utm_campaign=gmb_sanantonio",
    zip: "78217",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWY4OHQ5ZjRx",
    id: "0x865c5173dff7f9b5:0x480bbc15e8617fe1",
    niche: "Roofer",
    phone: "+12104280165",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "26",
    state: "Texas",
    street: "",
    title: "Expertise Roofing",
    website: "https://expertiseroofing.com/",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWJ4ZjNkNm5k",
    id: "0x865c651422ed2937:0xb0cf26d0b6b67d94",
    niche: "Roofer",
    phone: "+12109123360",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "11",
    state: "Texas",
    street: "24165 I-10 Ste 217",
    title: "American Roofing and Remodeling",
    website: "http://www.americanroofingandremodeling.com/",
    zip: "78257",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXRzaDI0MHQ0",
    id: "0x865cf7f433d62b29:0x6d75e9055337f32d",
    niche: "Roofer",
    phone: "+12102511847",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "9",
    state: "Texas",
    street: "",
    title: "Roofers In San Antonio ( Local Roofers ) LLC",
    website: "",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWd4X3ZfbmJo",
    id: "0x865c61747261f433:0x91401dbf25576cb0",
    niche: "Roofer",
    phone: "+12104451087",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "7",
    state: "Texas",
    street: "318 E Nakoma Dr suite 113",
    title: "Premium commercial roofing LLC.",
    website: "http://premiumcommercialroofingllc.com/",
    zip: "78216",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXM3X3h5ZnBo",
    id: "0x2d88cadf37570e29:0x1d49d7d4e644c733",
    niche: "Roofer",
    phone: "+12108124878",
    query: "San Antonio, Texas Roofer",
    rating: "4.3",
    reviews: "6",
    state: "Texas",
    street: "",
    title: "Roof Angels TX LLC",
    website: "http://roofangels.net/?utm_source=gmb&utm_medium=referral",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=CgsvZy8xdGZidDk4Zg%3D%3D",
    id: "0x865be321f7147fc3:0x7125f801aa2dea08",
    niche: "Roofer",
    phone: "+12104323516",
    query: "San Antonio, Texas Roofer",
    rating: "3.9",
    reviews: "19",
    state: "Texas",
    street: "2754 Culebra Rd",
    title: "J R Guerrero Roofing",
    website: "https://www.jrguerreroroofingtx.com/",
    zip: "78228",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWNtX2hzZ3l2",
    id: "0x865c5f84544cf7cb:0x609fc7b94e2a5e87",
    niche: "Roofer",
    phone: "+12109043075",
    query: "San Antonio, Texas Roofer",
    rating: "4.8",
    reviews: "9",
    state: "Texas",
    street: "5225 McCullough Ave",
    title: "San Antonio Roof Experts",
    website: "https://www.sanantonioroofexperts.com/",
    zip: "78212",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWZtM3ZoNXZw",
    id: "0x865c585b3e940001:0xca37946cfa83bbfc",
    niche: "Roofer",
    phone: "+12108806132",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "6",
    state: "Texas",
    street: "",
    title:
      "JCV Roofing JCV Roofing | Painting Services | Roofing Services in San Antonio TX",
    website: "",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWtzNmg0MTVw",
    id: "0x46d94e4dcb3a81df:0x1a455b010cec56ce",
    niche: "Roofer",
    phone: "+12109813346",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "6",
    state: "Texas",
    street: "",
    title: "Superior Roofing San Antonio",
    website: "https://www.sanantonio-roofing.com/",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXFweWJmZDg4",
    id: "0x865c5fe472900f77:0xe3857473df3067f1",
    niche: "Roofer",
    phone: "+15129449000",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "9",
    state: "Texas",
    street: "",
    title: "Hytek Builders",
    website: "https://www.hytekroofing.com/",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWZsbDZtZDJ4",
    id: "0x865c58260f73ffff:0xa0a56a8e0550b589",
    niche: "Roofer",
    phone: "+12109995816",
    query: "San Antonio, Texas Roofer",
    rating: "4.4",
    reviews: "7",
    state: "Texas",
    street: "3709 Commercial Ave",
    title: "RRR Roofing And Remodeling",
    website: "https://www.rrr-roofing.com/",
    zip: "78221",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWgwbGpmZmsw",
    id: "0x865c673f0de08973:0xa71659ee6aa20e73",
    niche: "Roofer",
    phone: "+12105891035",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "13",
    state: "Texas",
    street: "1739 Babcock Rd",
    title: "OakTree Management Group LLC",
    website: "https://oaktreetexas.com/",
    zip: "78229",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWhfdHM2cGcz",
    id: "0x865c5dac3236dcfd:0xce7a1bca759f5c78",
    niche: "Roofer",
    phone: "+12103719982",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "2",
    state: "Texas",
    street: "",
    title: "South Texas Roofing",
    website: "",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=CgwvZy8xcTY2eXZjang%3D",
    id: "0x865c8ce90269c611:0x58e2f94b4c809303",
    niche: "Roofer",
    phone: "+12102762050",
    query: "San Antonio, Texas Roofer",
    rating: "4.8",
    reviews: "4",
    state: "Texas",
    street: "12200 Crownpoint Dr #160",
    title: "CentiMark Commercial Roofing",
    website: "http://www.centimark.com/locations/san-antonio-texas",
    zip: "78233",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWg1NG02czJu",
    id: "0x865cf9fb2229cb17:0x10ff7daaed5c5d13",
    niche: "Roofer",
    phone: "+12102276807",
    query: "San Antonio, Texas Roofer",
    rating: "3.6",
    reviews: "7",
    state: "Texas",
    street: "4709 Roosevelt Ave",
    title: "Accu-Rite Roofing and Construction Services",
    website: "http://www.accuriteroof.com/",
    zip: "78214",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMW1uMjV2ejlk",
    id: "0x865c5fa8f9e927c3:0x452f63c8e211fd71",
    niche: "Roofer",
    phone: "+12109429797",
    query: "San Antonio, Texas Roofer",
    rating: "3.0",
    reviews: "2",
    state: "Texas",
    street: "415 Balcones Heights Rd",
    title: "Sky Roofing Construction & Remodeling",
    website: "https://skyroofingconstructiontx.com/",
    zip: "78201",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=CgsvZy8xdjYyajIxcA%3D%3D",
    id: "0x865c616f06cefe07:0x6771beadfc15243",
    niche: "Roofer",
    phone: "+12107777663",
    query: "San Antonio, Texas Roofer",
    rating: "4.5",
    reviews: "212",
    state: "Texas",
    street: "16106 University Oak Suite 2",
    title: "A-TEX Roofing & Remodeling",
    website: "https://atexroofing.com/",
    zip: "78249",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXB5NnNsOTd5",
    id: "0x865c67c3fac7de4b:0xb3fa75af855d6bf5",
    niche: "Roofer",
    phone: "+12109343903",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "3",
    state: "Texas",
    street: "",
    title: "RoofHelp! | San Antonio",
    website: "",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXQyN3NiZ24x",
    id: "0x865c5f4467dba3af:0xfa29e4660032c8ee",
    niche: "Roofer",
    phone: "+12104276418",
    query: "San Antonio, Texas Roofer",
    rating: "4.6",
    reviews: "13",
    state: "Texas",
    street: "302 Bradford Ave",
    title: "J.R Martínez roofing n remodeling",
    website: "",
    zip: "78228",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=CgwvZy8xMTNoOHliMGI%3D",
    id: "0x8640e3cd707017e3:0xbb33bc849ea74ad1",
    niche: "Roofer",
    phone: "+12107070007",
    query: "San Antonio, Texas Roofer",
    rating: "4.7",
    reviews: "106",
    state: "Texas",
    street: "10926 Braun Rd",
    title: "Vidal Construction",
    website: "http://www.vidalconstruction.com/",
    zip: "78254",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWhjdzVqa3Zu",
    id: "0x865c434b7d615555:0x81c50d7f18895aff",
    niche: "Roofer",
    phone: "+12108310630",
    query: "San Antonio, Texas Roofer",
    rating: "4.0",
    reviews: "13",
    state: "Texas",
    street: "",
    title: "Shinn Roofing",
    website: "http://shinnroofing.com/",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWp3a2g2ZGIz",
    id: "0x865cf5c9dae4433f:0x3701470d5e822764",
    niche: "Roofer",
    phone: "+12109202593",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "1",
    state: "Texas",
    street: "3510 N St Mary's St",
    title: "San Antonio Roofing Contractors",
    website: "http://roof-contractor.org/San-Antonio-TX.html",
    zip: "78212",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXYzZ2hyNnI5",
    id: "0xa3637a4d442cc665:0x277f0ca028ee651b",
    niche: "Roofer",
    phone: "+12109478599",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "5",
    state: "Texas",
    street: "",
    title: "Primal Roofing",
    website: "https://primalroof.com/",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=CgsvZy8xdnBmano5aw%3D%3D",
    id: "0x865cf627ee14c671:0x453f94fd6b9453bd",
    niche: "Roofer",
    phone: "+12108460105",
    query: "San Antonio, Texas Roofer",
    rating: "3.3",
    reviews: "10",
    state: "Texas",
    street: "871 Steves Ave",
    title: "Martinez Roofing",
    website: "",
    zip: "78210",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWI2Zms1YzJi",
    id: "0x865c8ace8b4fd84f:0xd1a1d0a89c410233",
    niche: "Roofer",
    phone: "+12103429600",
    query: "San Antonio, Texas Roofer",
    rating: "2.9",
    reviews: "19",
    state: "Texas",
    street: "",
    title: "Apex Roofing",
    website: "",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=CgsvZy8xdGZzdDkycw%3D%3D",
    id: "0x865c5d6ea603e6df:0x7038d3bc80436cfd",
    niche: "Roofer",
    phone: "+12103783804",
    query: "San Antonio, Texas Roofer",
    rating: "4.6",
    reviews: "9",
    state: "Texas",
    street: "",
    title: "EEROOFCO ( EERC )",
    website: "http://www.eeroofsco.com/",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=CgsvZy8xdGN6d2M1MQ%3D%3D",
    id: "0x865c91e140b040ad:0xf11c4123eff45ca6",
    niche: "Roofer",
    phone: "+12105924804",
    query: "San Antonio, Texas Roofer",
    rating: "4.9",
    reviews: "113",
    state: "Texas",
    street: "19942 FM 2252 bldg 2",
    title: "Yuras Roofing Company",
    website: "https://www.yurasroofing.com/",
    zip: "78266",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWgzYnNmenR3",
    id: "0x865cf50983f1c247:0xf835a1f0b920049d",
    niche: "Roofer",
    phone: "+12103417663",
    query: "San Antonio, Texas Roofer",
    rating: "3.0",
    reviews: "2",
    state: "Texas",
    street: "",
    title: "San Antonio Roofing Company",
    website: "",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWZ3YmxnZndm",
    id: "0x865c6139809d49d9:0xca7ce801a1ff5ae3",
    niche: "Roofer",
    phone: "+12108077755",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "3",
    state: "Texas",
    street: "8000 I-10 #600",
    title: "Infinity Roofing & Restoration",
    website: "https://www.infinityroofer.com/san-antonio-tx/contractor/",
    zip: "78230",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWRfYmt2Xzlm",
    id: "0x864e6bcbe1efffff:0x807977b20ba9a225",
    niche: "Roofer",
    phone: "+18172667323",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "40",
    state: "Texas",
    street: "102 Jon Ann #110",
    title: "Finished Work Roofing",
    website: "https://finishedworkroofing.com/",
    zip: "78201",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWo2dDhxajZm",
    id: "0x865cf5d40f248a13:0xf23bd3d68e6b19c4",
    niche: "Roofer",
    phone: "+12107924711",
    query: "San Antonio, Texas Roofer",
    rating: "1.0",
    reviews: "1",
    state: "Texas",
    street: "7801 Broadway",
    title: "CCR Roofing, LLC",
    website: "https://ccr-roofing.com/san-antonio/",
    zip: "78209",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWdtYng3bG12",
    id: "0x865c5f850cc6504d:0xee23f41b514c2e47",
    niche: "Roofer",
    phone: "+12103554225",
    query: "San Antonio, Texas Roofer",
    rating: "4.5",
    reviews: "2",
    state: "Texas",
    street: "207 Springwood Ln",
    title: "San Antonio Roofing",
    website: "",
    zip: "78216",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWtxOXc3ZDR3",
    id: "0x865cf760457313cd:0x6b50e17e00746d46",
    niche: "Roofer",
    phone: "+17264445374",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "1",
    state: "Texas",
    street: "310 Castillo Ave",
    title: "Metal Roofing San Antonio",
    website: "http://metalroofingsanantonio.xyz/",
    zip: "78210",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWY1NWttcnpw",
    id: "0x865c92771b65b689:0x33e1c6fce9a6f71a",
    niche: "Roofer",
    phone: "+12102908012",
    query: "San Antonio, Texas Roofer",
    rating: "4.8",
    reviews: "24",
    state: "Texas",
    street: "17323 Bell N Dr",
    title: "Casa Roofing, LLC",
    website: "https://casaroofingsa.com/",
    zip: "78154",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXRuaHI0Yjdy",
    id: "0x491080ffd22da561:0x62358d5ae23722a0",
    niche: "Roofer",
    phone: "+12103360688",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "3",
    state: "Texas",
    street: "203 Yolanda Dr",
    title: "SA Metal Roofing LLC",
    website: "https://sametalroofingllc.com/",
    zip: "78228",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWhkbmowX3g1",
    id: "0x865c8bb0bab08277:0xdeafbba3b52230d9",
    niche: "Roofer",
    phone: "+12106646000",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "14",
    state: "Texas",
    street: "",
    title: "Emerald Exteriors",
    website: "https://www.emeraldexteriors.net/",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXB3eXZtdHc2",
    id: "0x865c6340d93e034f:0x182bcfda157fbabb",
    niche: "Roofer",
    phone: "+18302373129",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "8",
    state: "Texas",
    street: "",
    title: "Skytex Construction",
    website: "http://www.skytexconstruction.com/",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXJ2YnozaGtt",
    id: "0x865c61bbca06df05:0x592631c9c09e24b1",
    niche: "Roofer",
    phone: "+15124003113",
    query: "San Antonio, Texas Roofer",
    rating: "",
    reviews: "",
    state: "Texas",
    street: "1846 N Loop 1604 W Suite 205-022",
    title: "San Antonio Roof Royale",
    website: "https://austinroofroyale.com/san-antonio-roofing-contractors/",
    zip: "78248",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWxkNGo2OHls",
    id: "0x865c61b42d681c1f:0x8dce234fe69950f8",
    niche: "Roofer",
    phone: "+12108436687",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "3",
    state: "Texas",
    street: "10010 San Pedro Ave Suite 325",
    title: "Alamo City Roofing",
    website: "https://alamocity-roofing.com/",
    zip: "78216",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXJwanFycm5m",
    id: "0x865c899dd55ae183:0x24582d019e01d3df",
    niche: "Roofer",
    phone: "+12102014344",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "11",
    state: "Texas",
    street: "237 Willow Bluff",
    title: "SA Roof CO, LLC",
    website: "https://sanantonioroofcompany.com/",
    zip: "78108",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWg3NmRoaDhq",
    id: "0x865c67deae327dcf:0x88f57c0edfcb21c9",
    niche: "Roofer",
    phone: "+12105430707",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "1",
    state: "Texas",
    street: "",
    title: "Perry Roofing",
    website: "",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWwybHpxbXFx",
    id: "0x865c61b83f22e84b:0x107902384ea33f2f",
    niche: "Roofer",
    phone: "+12109969887",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "4",
    state: "Texas",
    street: "1846 N Loop 1604 W",
    title: "Primary Roofing",
    website: "https://www.primaryroofs.com/",
    zip: "78248",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=CgsvZy8xdHg4dG1yag%3D%3D",
    id: "0x865c8b3d59f23af5:0xafc6b73e49a0d7bc",
    niche: "Roofer",
    phone: "+12106500000",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "4",
    state: "Texas",
    street: "3697 Highpoint St #104",
    title: "Aur Roofing Inc",
    website:
      "http://www.aurroofing.com/?ld_geo=GEO0005186&ld_aff=Googlemaps&ld_map=yes",
    zip: "78217",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXF3ejU5eDYy",
    id: "0x865cf568b2870db9:0xc3cd8e797ca7d1e5",
    niche: "Roofer",
    phone: "+12102415114",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "1",
    state: "Texas",
    street: "1100 NE Interstate 410 Loop # 508",
    title: "Roofing Rx, 1100 NE Loop 410 ste. 508, San Antonio Tx 78209",
    website: "http://www.roofingrxco.com/",
    zip: "78209",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWdqdDFibmI1",
    id: "0x865c67917b3d1caf:0x36ab3091e589968",
    niche: "Roofer",
    phone: "+12106861425",
    query: "San Antonio, Texas Roofer",
    rating: "",
    reviews: "",
    state: "Texas",
    street: "5460 Babcock Rd ste 120-307",
    title: "Stevan Buren Commercial Roofing San Antonio",
    website:
      "https://www.stevanburen.com/commercial/locations/commercial-roofing-san-antonio-tx",
    zip: "78240",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=CgwvZy8xMmNwa245X2M%3D",
    id: "0x865b37306e230c47:0x7a92fe2652880106",
    niche: "Roofer",
    phone: "+12104466214",
    query: "San Antonio, Texas Roofer",
    rating: "4.3",
    reviews: "3",
    state: "Texas",
    street: "9310 Broadway #202",
    title: "Universal Contracting",
    website: "http://universalcontractingtx.com/",
    zip: "78217",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWMybnRrcmRx",
    id: "0x865c8acc178969a9:0x43ec6c31ee60b5a2",
    niche: "Roofer",
    phone: "+12106991400",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "1",
    state: "Texas",
    street: "4135 Stahl Rd # 3",
    title: "Sustainable Edge Construction & Roofing",
    website: "https://www.sustainableedgeroofing.com/",
    zip: "78217",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWc0ajV5ZHgz",
    id: "0x865c8b63e731454b:0xca99617645e33682",
    niche: "Roofer",
    phone: "+12108996111",
    query: "San Antonio, Texas Roofer",
    rating: "",
    reviews: "",
    state: "Texas",
    street: "",
    title: "Done Right Roofing SA",
    website: "http://www.donerightroofingsa.com/",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWg5bTRqNHd4",
    id: "0x865cfb38b45726d3:0x8f7ad925352fbca",
    niche: "Roofer",
    phone: "+12103611098",
    query: "San Antonio, Texas Roofer",
    rating: "",
    reviews: "",
    state: "Texas",
    street: "",
    title: "Metal Roofing San Antonio",
    website: "https://www.metalroofingsanantonio.net/",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXE5OGhydHls",
    id: "0x865cf714e2f198f7:0xb807ed534238bdb1",
    niche: "Roofer",
    phone: "+18309711280",
    query: "San Antonio, Texas Roofer",
    rating: "",
    reviews: "",
    state: "Texas",
    street: "101 Bowie St",
    title: "Method Roofer San Antonio",
    website:
      "https://method-roofer-san-antonio.business.site/?utm_source=gmb&utm_medium=referral",
    zip: "78205",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWo3ajRxa2Mw",
    id: "0x865c6f605b92b909:0xf1ec71231d9be36b",
    niche: "Roofer",
    phone: "+12106850409",
    query: "San Antonio, Texas Roofer",
    rating: "",
    reviews: "",
    state: "Texas",
    street: "10423 Foxen Way",
    title: "Independence Roofing of San Antonio",
    website: "https://independenceroofingsa.com/",
    zip: "78023",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWg2djFwbHA1",
    id: "0x865c61b23f4bee9b:0x99da47e34df8e037",
    niche: "Roofer",
    phone: "+12105047663",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "4",
    state: "Texas",
    street: "8400 Blanco Rd #205",
    title: "Trophy Roofing",
    website: "",
    zip: "78216",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXFsMXg2bHQ3",
    id: "0x865c5b04545ee871:0xc60619ffb2ed9e8f",
    niche: "Roofer",
    phone: "+12109937654",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "1",
    state: "Texas",
    street: "",
    title: "Aberz Roofing",
    website: "https://aberz-roofing.ueniweb.com/?utm_campaign=gmb",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWo5ZjJ0eHl3",
    id: "0x865c63dcb3e638a5:0xaeb0b99250c198ee",
    niche: "Roofer",
    phone: "+18302834688",
    query: "San Antonio, Texas Roofer",
    rating: "",
    reviews: "",
    state: "Texas",
    street: "",
    title: "San Antonio Roofing Professionals",
    website: "https://www.sanantonio-roof.com/",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWcxcG15eTBr",
    id: "0x865c93a38a118d5d:0x69ab8e723eac1f33",
    niche: "Roofer",
    phone: "+12104608896",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "26",
    state: "Texas",
    street: "3719 Lazy Diamond",
    title: "Live Oak Roofing",
    website: "http://www.liveoakroofs.com/",
    zip: "78154",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXJfbDk3ODRx",
    id: "0x6797ce537171f6f7:0xc2db822a23804e34",
    niche: "Roofer",
    phone: "+18302507663",
    query: "San Antonio, Texas Roofer",
    rating: "4.9",
    reviews: "28",
    state: "Texas",
    street: "",
    title: "Boomerang Building & Repairs",
    website: "https://www.boomerangbuilding.com/",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWhoOWQ5cG5i",
    id: "0x865c5be309aaaaab:0x1ee7a3de96ca33b4",
    niche: "Roofer",
    phone: "+12105900293",
    query: "San Antonio, Texas Roofer",
    rating: "",
    reviews: "",
    state: "Texas",
    street: "2315 SW 36th St",
    title: "Roofer San Antonio",
    website: "",
    zip: "78237",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=CgsvZy8xdGhsbmhneg%3D%3D",
    id: "0x865cf054cd2592f9:0xc41c16c067fdbfb2",
    niche: "Roofer",
    phone: "+12106489380",
    query: "San Antonio, Texas Roofer",
    rating: "",
    reviews: "",
    state: "Texas",
    street: "7150 Whippoorwill Ln Suite #1",
    title: "Marlowe Roofing Inc",
    website: "http://www.marloweroofing.com/",
    zip: "78263",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXJjODhzcnk5",
    id: "0x865c5f4b409517b7:0xdc8db072bf11b0c1",
    niche: "Roofer",
    phone: "+12109074282",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "1",
    state: "Texas",
    street: "2755 Culebra Rd",
    title: "Tile Roof Specialists GD Roofing",
    website: "https://gdroofingtexas.com/",
    zip: "78228",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWdkeW16d3Ji",
    id: "0x865c5de2332d81a9:0xc9fdf75e214603f7",
    niche: "Roofer",
    phone: "+12108995835",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "1",
    state: "Texas",
    street: "",
    title: "24/7 Free Roof Inspection | San Antonio Roofing Services",
    website: "https://roofrepairsanantoniotx.com/",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXQ1bGxwNzVo",
    id: "0x865c63f60365e375:0xc6db58db5894e40b",
    niche: "Roofer",
    phone: "+12104177728",
    query: "San Antonio, Texas Roofer",
    rating: "",
    reviews: "",
    state: "Texas",
    street: "210 E Sonterra Blvd Unit 211",
    title: "The Roofing Artist",
    website: "http://theroofingartist.com/",
    zip: "78258",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWc2bWhtNXls",
    id: "0x865c5f5392c32b9f:0xcf37488385f5b875",
    niche: "Roofer",
    phone: "+18883458328",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "2",
    state: "Texas",
    street: "300 Convent St #1330",
    title: "ARS Accurate Roofing Systems",
    website: "http://www.accurateroofingsystems.com/",
    zip: "78205",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWszd3IzanJx",
    id: "0x2383dabb286c28a3:0x64ddc5a93abd5fc",
    niche: "Roofer",
    phone: "+18176562288",
    query: "San Antonio, Texas Roofer",
    rating: "",
    reviews: "",
    state: "Texas",
    street: "",
    title: "MidCities Roofing Inc",
    website: "http://midcitiesroofing.com/",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=CgsvZy8xdGY2bW1wNw%3D%3D",
    id: "0x865c5dc3ff9f2e89:0x3603217734f4e85",
    niche: "Roofer",
    phone: "+12107104372",
    query: "San Antonio, Texas Roofer",
    rating: "4.9",
    reviews: "77",
    state: "Texas",
    street: "5301 Bandera Rd",
    title: "Herbert H Construction",
    website: "https://www.herberthconstructionsa.com/",
    zip: "78238",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=CgsvZy8xdGQ2bjJiOQ%3D%3D",
    id: "0x86685e6400000001:0xe23e209e627748a9",
    niche: "Roofer",
    phone: "",
    query: "San Antonio, Texas Roofer",
    rating: "4.8",
    reviews: "99",
    state: "Texas",
    street: "2118 Mannix Dr #101",
    title: "SOLA-LITE | SOLAR-TEX",
    website: "http://guildtx.com/",
    zip: "78217",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXYxNW5ncTlo",
    id: "0x4f496b38a337a8ed:0xec5cc0c32d9edd00",
    niche: "Roofer",
    phone: "+12107718091",
    query: "San Antonio, Texas Roofer",
    rating: "",
    reviews: "",
    state: "Texas",
    street: "",
    title: "San Antonio Roofing & Remodeling",
    website: "https://www.saroofingandremodeling.com/",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWtiYm1xMGd2",
    id: "0x865c5df74c418741:0x36849bcc71245a4d",
    niche: "Roofer",
    phone: "+12102433800",
    query: "San Antonio, Texas Roofer",
    rating: "1.0",
    reviews: "1",
    state: "Texas",
    street: "",
    title: "Gale Contracting Services",
    website: "https://roofingcontractorsanantoniotx.com/",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXR3cTM0aHY1",
    id: "0x865c68217e4054a1:0xc5840941254cc63b",
    niche: "Roofer",
    phone: "+12102969207",
    query: "San Antonio, Texas Roofer",
    rating: "",
    reviews: "",
    state: "Texas",
    street: "7258 Artisan Ln",
    title: "BH Roofing",
    website: "https://www.bhroofingsa.com/",
    zip: "78250",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWtoanhqcDNy",
    id: "0x865c5f311a9922cf:0x2beace3c762fd559",
    niche: "Roofer",
    phone: "+12105924766",
    query: "San Antonio, Texas Roofer",
    rating: "3.3",
    reviews: "7",
    state: "Texas",
    street: "341 University Ave.",
    title: "Ironclad Roofing",
    website: "https://ironcladroof.com/",
    zip: "78201",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWc3ejd5MG0w",
    id: "0x865c947e92d9447b:0x631e46e853811775",
    niche: "Roofer",
    phone: "+12108588880",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "36",
    state: "Texas",
    street: "",
    title: "SoTex Roofing and Exteriors",
    website: "http://www.sotexexteriors.com/",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWoyM3BnNXBi",
    id: "0x865c636e41126ebb:0xe216336bf7a8d998",
    niche: "Roofer",
    phone: "+12103388141",
    query: "San Antonio, Texas Roofer",
    rating: "",
    reviews: "",
    state: "Texas",
    street: "21750 Hardy Oak Blvd #102",
    title: "Titan Roofing, LLC",
    website: "",
    zip: "78258",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMW1yMzA4Mnpr",
    id: "0x865c5fb0bd838cef:0xa5d1a7fc41b3e0be",
    niche: "Roofer",
    phone: "+12103385439",
    query: "San Antonio, Texas Roofer",
    rating: "",
    reviews: "",
    state: "Texas",
    street: "2300 W Commerce St #216",
    title: "3m Roofing Company",
    website: "",
    zip: "78207",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWNtYjljN3ox",
    id: "0x87b781ec0ab42ea5:0x20cda378b413e5af",
    niche: "Roofer",
    phone: "+18559555554",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "1",
    state: "Texas",
    street: "11311 Sir Winston St APT 403",
    title: "American R & R",
    website: "",
    zip: "78216",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWg3MW45eWcy",
    id: "0x865c5fccec6a8201:0x535a67824e06335e",
    niche: "Roofer",
    phone: "+12108996144",
    query: "San Antonio, Texas Roofer",
    rating: "",
    reviews: "",
    state: "Texas",
    street: "",
    title: "CityPro Roofing San Antonio TX",
    website: "",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXNwcjFtNnR6",
    id: "0x865cede4b39d86c3:0x63ab1429b7899eb2",
    niche: "Roofer",
    phone: "+18303139454",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "3",
    state: "Texas",
    street: "12881 Woman Hollering Rd",
    title: "Ginger Roofing Company",
    website: "https://www.gingerroofingcompany.com/",
    zip: "78154",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=CgsvZy8xdHd5enprZw%3D%3D",
    id: "0x865ca32f99976ec9:0x20df7aa94f8e000d",
    niche: "Roofer",
    phone: "+18306207663",
    query: "San Antonio, Texas Roofer",
    rating: "4.7",
    reviews: "111",
    state: "Texas",
    street: "5130 S I-35",
    title: "Radiant Roofing",
    website: "http://www.radroof.com/",
    zip: "78132",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWZ2NzU4Mnc5",
    id: "0x865c615d02cedfcb:0xa45aa54985933626",
    niche: "Roofer",
    phone: "+12106866338",
    query: "San Antonio, Texas Roofer",
    rating: "",
    reviews: "",
    state: "Texas",
    street: "1100 Northwest Loop 410 #700",
    title: "Affluent Exteriors",
    website: "https://www.affluentexteriors.com/",
    zip: "78213",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXNneGs0Y2N5",
    id: "0x4733c58fd7291ccb:0x2afb33911a3c2f8e",
    niche: "Roofer",
    phone: "+12104412717",
    query: "San Antonio, Texas Roofer",
    rating: "",
    reviews: "",
    state: "Texas",
    street: "",
    title: "Texas Roof Coating LLC",
    website: "http://www.texasroofcoatingllc.com/",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXkxdjl6emYx",
    id: "0x86241d9ab1e2e1c7:0x708b6dd8ddcd0aac",
    niche: "Roofer",
    phone: "+12102694052",
    query: "San Antonio, Texas Roofer",
    rating: "",
    reviews: "",
    state: "Texas",
    street: "",
    title: "Rebot Roofing",
    website: "https://rebotroofing.com/",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWg3czFzN25m",
    id: "0x865cf3ef6b112fcd:0xf92b1b4d47cb381e",
    niche: "Roofer",
    phone: "+12103894562",
    query: "San Antonio, Texas Roofer",
    rating: "4.9",
    reviews: "29",
    state: "Texas",
    street: "141 Danube Dr Suite 202",
    title: "M Construction & Remodeling Group, LLC",
    website: "https://www.mcr-groupllc.com/",
    zip: "78213",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXRma2xmazl4",
    id: "0xa002520c50c06151:0xc9cdc46e971e7c78",
    niche: "Roofer",
    phone: "+12109728398",
    query: "San Antonio, Texas Roofer",
    rating: "",
    reviews: "",
    state: "Texas",
    street: "8719 Converse Business Cir #1",
    title: "StoneHouse Roofers",
    website: "http://stonehouseroofers.com/",
    zip: "78109",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWo2cmtxbXI0",
    id: "0x865cf5d64cf7e8b7:0x12dce40d187a55c4",
    niche: "Roofer",
    phone: "+12103302274",
    query: "San Antonio, Texas Roofer",
    rating: "",
    reviews: "",
    state: "Texas",
    street: "2441 Nacogdoches Rd Ste 525",
    title: "Home Pro Repair and Remodels",
    website: "",
    zip: "78217",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXJiZHhqemgw",
    id: "0x866f97fe37da1101:0xfcdbc2446d3b73b1",
    niche: "Roofer",
    phone: "+14322124660",
    query: "San Antonio, Texas Roofer",
    rating: "4.2",
    reviews: "10",
    state: "Texas",
    street: "",
    title: "Exxodus Builders LLC",
    website: "",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=CgsvZy8xeHB3azY3ag%3D%3D",
    id: "0x865c8c48c2099ebf:0x7bdc01a7e7c76cb1",
    niche: "Roofer",
    phone: "+12102014018",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "13",
    state: "Texas",
    street: "616 Rittiman Rd",
    title: "Howell Enterprises",
    website: "http://www.howell-enterprises.com/",
    zip: "78209",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=CgsvZy8xeHB3azY3ag%3D%3D",
    id: "0x865c8c48c2099ebf:0x7bdc01a7e7c76cb1",
    niche: "Roofer",
    phone: "+12102014018",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "13",
    state: "Texas",
    street: "616 Rittiman Rd",
    title: "Howell Enterprises",
    website: "http://www.howell-enterprises.com/",
    zip: "78209",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWZra3p3NnR3",
    id: "0x865c8b9b94671a5f:0xd4e9e26b42481348",
    niche: "Roofer",
    phone: "+12108668838",
    query: "San Antonio, Texas Roofer",
    rating: "4.9",
    reviews: "29",
    state: "Texas",
    street: "9740 O'Connor Rd",
    title: "Boling Construction and Restoration Company",
    website: "https://bolingconstructioncompany.com/",
    zip: "78109",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXEyMmpfdHpt",
    id: "0x865c59ea86f644f3:0xd38eb160596a397c",
    niche: "Roofer",
    phone: "+18305424055",
    query: "San Antonio, Texas Roofer",
    rating: "",
    reviews: "",
    state: "Texas",
    street: "4910 S Zarzamora St #239",
    title: "Pa's Construction, LLC",
    website: "http://www.pasroofingandconstruction.com/",
    zip: "78211",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMXJ2YnRzMXd0",
    id: "0x41027313622c36c5:0x92c80f33610f5618",
    niche: "Roofer",
    phone: "+18303275055",
    query: "San Antonio, Texas Roofer",
    rating: "",
    reviews: "",
    state: "Texas",
    street: "",
    title: "Texas Roofer",
    website: "http://itexasroofer.com/",
    zip: "",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMW5oNWJ6NV9j",
    id: "0x865c43fd4b8a5e75:0x652e1c36b91725e",
    niche: "Roofer",
    phone: "+12109813932",
    query: "San Antonio, Texas Roofer",
    rating: "",
    reviews: "",
    state: "Texas",
    street: "10122 Cedarmont Dr",
    title: "Roofing San Antonio",
    website: "",
    zip: "78245",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWptemswc2pn",
    id: "0x865c8b60d9e6da97:0xd6271cbfdeb8690d",
    niche: "Roofer",
    phone: "",
    query: "San Antonio, Texas Roofer",
    rating: "1.0",
    reviews: "1",
    state: "Texas",
    street: "2515 Boardwalk St",
    title: "Goliath Roofing And Remodeling San Antonio",
    website: "",
    zip: "78217",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=Cg0vZy8xMWh5X3hsejYw",
    id: "0x865c4314e6e1e4ab:0xb9da077006494fd7",
    niche: "Roofer",
    phone: "+12103612213",
    query: "San Antonio, Texas Roofer",
    rating: "",
    reviews: "",
    state: "Texas",
    street: "114 N Ellison Dr",
    title: "Roofing Repairs",
    website: "",
    zip: "78251",
  },
  {
    city: "San Antonio",
    emails: [],
    gmb: "https://www.google.com/localservices/profile?spp=CgsvZy8xeGdybmJ6Zg%3D%3D",
    id: "0x865c9366b456dbb7:0x7d3f45c45fe24a12",
    niche: "Roofer",
    phone: "+12102297777",
    query: "San Antonio, Texas Roofer",
    rating: "5.0",
    reviews: "3",
    state: "Texas",
    street: "149 Village Green #420",
    title: "San Antonio Roof",
    website: "",
    zip: "78148",
  },
];
